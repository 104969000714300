import React from 'react';
import classNames from 'classnames';

import './index.scss';

type Props = {
  children: string;
  pattern: Pattern;
};

type Pattern = 'required' | 'disabled';

const Label: React.FC<Props> = ({children, pattern}) => {
  return (
    <div>
      <div
        className={classNames({
          a__label: true,
          required: pattern === 'required',
          disabled: pattern === 'disabled',
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Label;
