import moment, {MomentInput, unitOfTime} from 'moment';
moment.locale('ja', {
  weekdays: [
    '日曜日',
    '月曜日',
    '火曜日',
    '水曜日',
    '木曜日',
    '金曜日',
    '土曜日',
  ],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
});

export const now = (format?: string) => {
  return moment().format(format);
};

export const subDate = (
  unit: unitOfTime.DurationConstructor,
  amount: string | number,
  format?: string
) => {
  return moment().subtract(unit, amount).format(format);
};

export const addDate = (
  unit: unitOfTime.DurationConstructor,
  amount: string | number,
  format?: string
) => {
  return moment().add(unit, amount).format(format);
};

export const getDisplay = (date: MomentInput) => {
  return moment(date).format('YYYY-MM-DD');
};

export const format = (
  baseDate: Date | string,
  baseFormat?: string,
  dateFormat?: string,
  isSearch?: boolean,
  isValidated: boolean = true
) => {
  let targetDate: moment.Moment | string;
  if (baseFormat) {
    targetDate = moment(baseDate, baseFormat);
  } else {
    targetDate = moment(baseDate, 'YYYY-MM-DD');
  }

  // Invalid date対策
  const isValidDate = moment(targetDate, 'YYYY-MM-DD').isValid();

  targetDate = dateFormat
    ? targetDate.format(dateFormat)
    : targetDate.format(baseFormat);

  if (isValidDate && isSearch) {
    return `${targetDate}-23:59:59`;
  }

  if (isValidDate) {
    return targetDate;
  } else {
    if (isValidated) {
      targetDate = '';
    }

    return targetDate;
  }
};

export const mergeDate = (y: any, m: any, d: any, format?: string) => {
  return moment([y, m - 1, d]).format(format);
};
