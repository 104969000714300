import * as signUp from './signUp';
import * as signIn from './signIn';
import * as sales from './sales';
import * as stocks from './stocks';

export default {
  signUp,
  signIn,
  sales,
  stocks,
};
