import React from 'react';
import classNames from 'classnames';

import './index.scss';

type Props = {
  initialValue: string;
  index: number;
  id: string;
  error?: boolean;
  updateMyData: (index: number, id: string, value: string) => void;
  inputType?: React.InputHTMLAttributes<HTMLInputElement>['type'];
};

const EditableCell: React.FC<Props> = ({
  initialValue,
  index,
  id,
  error,
  updateMyData,
  inputType,
}) => {
  const [value, setValue] = React.useState<string>(initialValue);
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    updateMyData(index, id, value);
  };

  return (
    <input
      className={classNames('a__table-editable-cell', {error})}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      type={inputType}
    />
  );
};

export default EditableCell;
