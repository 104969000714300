import Amplify, {Auth} from 'aws-amplify';

import utils from '@/lib/utils';

export const init = () => {
  const env: any = utils.env.get();

  Amplify.configure({
    Auth: {
      region: env.COGNITO_REGION,
      userPoolId: env.COGNITO_USER_POOL_ID,
      userPoolWebClientId: env.COGNITO_CLIENT_ID,
    },
  });
};

export const signIn = async (email: string, password: string) => {
  const user = await Auth.signIn({
    username: email,
    password,
  });

  return user;
};

export const singUp = async (email: string, password: string) => {
  const newUser = await Auth.signUp({
    username: email,
    password,
    attributes: {
      email,
    },
  });

  return newUser;
};

export const confirmSignUp = async (email: string, code: string) => {
  const user = await Auth.confirmSignUp(email, code);
  return user;
};

export const signOut = async () => {
  // After retrieving the confirmation code from the user
  const result = await Auth.signOut();
  return result;
};

export const changePassword = async (
  oldPassword: string,
  newPassword: string
) => {
  const user = await Auth.currentAuthenticatedUser();
  const result = await Auth.changePassword(user, oldPassword, newPassword);

  return result;
};

export const forgotPassword = async (username: string) => {
  const result = await Auth.forgotPassword(username);
  return result;
};

export const forgotPasswordSubmit = async (
  username: string,
  code: string,
  newPassword: string
) => {
  await Auth.forgotPasswordSubmit(username, code, newPassword);
};

export const getUser = async () => {
  const user = await Auth.currentAuthenticatedUser({
    bypassCache: false,
  });

  return user;
};

export const getIdToken = async () => {
  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    return token;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * GetCurrentSession
 */
export const getCurrentSession = async () => {
  try {
    const result = await Auth.currentSession();
    return result;
  } catch (err) {
    return err;
  }
};
