import React from 'react';
import {Button} from 'semantic-ui-react';

import {Props} from './types';

const Circle: React.FC<Props> = (props) => {
  return (
    <Button
      icon={props.icon}
      onClick={() => {
        props.onClick();
      }}
    />
  );
};

export default Circle;
