import Block from './Block';
import Block2 from './Block2';

import Default from './Default';

export default {
  Block,
  Block2,
  Default,
};
