import signIn from './signIn';
import resetPassword from './resetPassword';
import sales from './sales';
import stocks from './stocks';
import shippingCosts from './shippingCosts';

export default {
  signIn,
  resetPassword,
  sales,
  stocks,
  shippingCosts,
};
