import notify from '@/migration/notification';

import paths from '@/config/paths';
import utils from '@/lib/utils';
import {Submit} from './types';

export const submit: Submit = () => async (values, actions) => {
  try {
    await utils.amplify.signIn(values.email, values.password);
    utils.storage.setIsAuthed(true);

    window.location.href = paths.sales.main;
  } catch (err) {
    notify('ログインに失敗しました', 'error');
    console.error(err);
  } finally {
    actions.setSubmitting(false);
  }
};
