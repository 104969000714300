import React, {useState} from 'react';

import _Base from './../_Base';
import {Formik, FormikProps, Field} from 'formik';
import {Button, Form, Label} from '@/components';
import {Props} from './types';
import {Rating} from 'semantic-ui-react';
import * as handlers from './handlers';

import './index.scss';

const Review = (props: Props) => {
  const [rating, setRating] = useState(0);
  const initialValues = {
    body: '',
  };

  return (
    <_Base
      title="落札者レビュー"
      isModal={props.isModal}
      handleCloseModal={props.setIsModal}
      size="sm"
      isAutoClose={false}
    >
      <div className="a__review-modal">
        <Formik
          initialValues={initialValues}
          onSubmit={async (value) => {
            await handlers.submit(
              props.saleId,
              props.meId,
              props.boughtUserId,
              props.boughtUserName,
              rating,
              setRating,
              props.setIsModal,
              value
            );
          }}
        >
          {(formProps: FormikProps<any>) => {
            return (
              <>
                <div style={{width: '100%'}}>
                  <div className="w__message">
                    {props.boughtUserName}さんとの取引はいかがでしたか？
                    <br /> 採点してください。
                  </div>
                  <div className="b__rating-container">
                    <Rating
                      rating={rating}
                      maxRating={5}
                      icon="star"
                      size="massive"
                      onRate={(event, data) => {
                        setRating(data.rating as number);
                      }}
                    />
                    <Label pattern="required">必須</Label>
                  </div>
                  <div className="b__body-container">
                    <span className="w__label">コメント</span>
                    <Label pattern="disabled">任意</Label>
                  </div>
                  <Field
                    name="body"
                    component={Form.TextArea}
                    placeholder="レビューを書こう！"
                    disabled={formProps.isSubmitting}
                    dark
                    fluid
                  />
                </div>
                <div className="w__modal-buttons">
                  <Button.Block
                    disabled={formProps.isSubmitting}
                    onClick={() => {
                      setRating(props.rating);
                      props.setIsModal(!props.isModal);
                    }}
                  >
                    キャンセル
                  </Button.Block>
                  <Button.Block
                    loading={formProps.isSubmitting}
                    disabled={formProps.isSubmitting}
                    color="edit"
                    onClick={() => formProps.handleSubmit()}
                  >
                    レビューする
                  </Button.Block>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </_Base>
  );
};
export default Review;
