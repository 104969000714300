import api from '@/api';
import notify from '@/migration/notification';

import {CreateUsersRate} from '@/api/users';

export const submit = async (
  saleId: number,
  userId: number,
  boughtUserId: number,
  boughtUserName: string,
  rating: number,
  setRating: (rating: 0) => void,
  setIsModal: (isModal: boolean) => void,
  value: any
) => {
  try {
    if (rating === 0) {
      notify('★1以上を選択しなければレビューできません', 'error');
      return;
    }
    const b: CreateUsersRate = {
      dist_user_id: boughtUserId,
      sale_id: saleId,
      point: rating,
      comment: value.body,
    };
    await api.users.rate(userId, b);

    setIsModal(false);
    setRating(0);

    notify(
      `落札者の${boughtUserName}さんを★${rating}でレビューしました`,
      'success',
      null,
      5000
    );
  } catch (error) {
    notify('落札者のレビューに失敗しました', 'error');
  }
};
