import React from 'react';

import {Props} from './types';

import './index.scss';

const Search: React.FC<Props> = (props) => {
  return <div className="a__search">{props.children}</div>;
};

export default Search;
