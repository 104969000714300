import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import api from '@/api';

export type MeState = {
  id: number;
  displayName: string;
  isCompany: boolean;
  shippingProvider: number;
};

const initialState: MeState = {
  id: 0,
  displayName: '',
  isCompany: false,
  shippingProvider: 0,
};

export const fetchMe = createAsyncThunk('me/fetchMe', async () => {
  const meResponse = await api.users.getMe();

  const me: MeState = {
    id: meResponse.id,
    displayName: meResponse.display_name,
    isCompany: meResponse.is_company,
    shippingProvider: meResponse.shipping_provider,
  };

  return {me};
});

const me = createSlice({
  name: 'me',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMe.fulfilled, (state, {payload}) => {
      state.id = payload.me.id;
      state.displayName = payload.me.displayName;
      state.isCompany = payload.me.isCompany;
      state.shippingProvider = payload.me.shippingProvider;
    });
  },
});

export default me.reducer;
