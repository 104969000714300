import React from 'react';
import {Link as RLink} from 'react-router-dom';

import {Props} from './types';

import './index.scss';

const Link: React.FC<Props> = (props) => {
  return (
    <RLink to={props.to} className="a__table-link c__td">
      {props.value}
    </RLink>
  );
};

export default Link;
