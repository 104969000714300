import React from 'react';

import './index.scss';

const Error: React.FC = (props) => {
  const errMsg = props.children;

  return <div className="a__form-error">{errMsg}</div>;
};

export default Error;
