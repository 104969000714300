import React from 'react';
import {CellProps} from 'react-table';
import _ from 'lodash';

import {Table} from '@/components';
import utils from '@/lib/utils';

export const sizes = [60, 80, 100, 120, 140, 160, 170] as const;

export const regions = {
  // hokkaido は北海道の地方名
  hokkaido: '北海道',
  // kitaTohoku は北東北の地方名
  kitaTohoku: '北東北',
  // minamiTohoku は南東北の地方名
  minamiTohoku: '南東北',
  // kanto : は関東の地方名
  kanto: '関東',

  tokyo: '東京',

  // shinetsu は信越の地方名
  shinetsu: '信越',
  // hokuriku は北陸の地方名
  hokuriku: '北陸',
  // tokai  : 東海 は東海の地方名
  tokai: '東海',
  // kansai : 関西 は関西の地方名
  kansai: '関西',
  // chugoku は中国の地方名
  chugoku: '中国',

  // shikoku は四国の地方名
  shikoku: '四国',
  // kita_kyushu は北九州の地方名
  kitaKyushu: '北九州',

  // minami_kyushu は南九州の地方名
  minamiKyushu: '南九州',

  // okinawa は沖縄の地方名
  okinawa: '沖縄',
};

export const regions2 = [
  {
    prefectures: ['北海道'],
    region: 'hokkaido',
  },
  {
    prefectures: ['青森県', '秋田県', '岩手県'],
    region: 'kitaTohoku',
  },
  {
    prefectures: ['宮城県', '山形県', '福島県'],
    region: 'minamiTohoku',
  },
  {
    prefectures: [
      '茨城県',
      '栃木県',
      '群馬県',
      '埼玉県',
      '千葉県',
      '神奈川県',
      '山梨県',
    ],
    region: 'kanto',
  },
  {
    prefectures: ['東京都'],
    region: 'tokyo',
  },
  {
    prefectures: ['新潟県', '長野県'],
    region: 'shinetsu',
  },
  {
    prefectures: ['富山県', '石川県', '福井県'],
    region: 'hokuriku',
  },
  {
    prefectures: ['静岡県', '愛知県', '三重県', '岐阜県'],
    region: 'tokai',
  },
  {
    prefectures: ['大阪府', '京都府', '滋賀県', '奈良県', '和歌山県', '兵庫県'],
    region: 'kansai',
  },
  {
    prefectures: ['岡山県', '広島県', '山口県', '鳥取県', '島根県'],
    region: 'chugoku',
  },
  {
    prefectures: ['香川県', '徳島県', '愛媛県', '高知県'],
    region: 'shikoku',
  },
  {
    prefectures: ['福岡県', '佐賀県', '長崎県', '大分県'],
    region: 'kitaKyushu',
  },
  {
    prefectures: ['熊本県', '宮崎県', '鹿児島県'],
    region: 'minamiKyushu',
  },
  {
    prefectures: ['沖縄県'],
    region: 'okinawa',
  },
] as const;

export type Region = typeof regions;
export type Column = Region & {size: number};
export type RelayColumn = {
  [key in ShippingRelayCostRegion]: {
    remoteIslandGroupId: number;
    relayCost: string;
  };
};

export const makeColumns = (
  updateMyData: (index: number, id: string, value: string) => void
) => {
  const columnMap = _.map(regions, (value, key) => {
    return {
      Header: value,
      accessor: key,
      width: 120,
      Cell: ({cell, column, row}: CellProps<any>) => (
        <Table.Edit
          initialValue={cell.value}
          index={row.index}
          id={column.id}
          updateMyData={updateMyData}
          error={cell.value === '' || !utils.string.isInteger(cell.value)}
          inputType="number"
        />
      ),
    };
  });

  return [
    {
      Header: 'サイズ',
      accessor: 'size',
      width: 120,
      Cell: ({cell}: CellProps<any>) => cell.value,
    },
    ...columnMap,
  ];
};

export const makeRelayColumns = (
  updateRelayMyData: (index: number, id: string, value: string) => void
) => {
  const columnMap = _.map(regions, (value, key) => {
    return {
      Header: value,
      accessor: key,
      width: 120,
      Cell: ({cell, column, row}: CellProps<any>) => {
        if (cell.value) {
          const value = cell.value.relayCost;
          return (
            <Table.Edit
              initialValue={value}
              index={row.index}
              id={column.id}
              updateMyData={updateRelayMyData}
              error={value === '' || !utils.string.isInteger(value)}
              inputType="number"
            />
          );
        } else {
          return 'なし';
        }
      },
    };
  });

  return [
    {
      Header: '離島',
      accessor: 'ritou',
      width: 120,
      Cell: '離島',
    },
    ...columnMap,
  ];
};
