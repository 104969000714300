import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import {useHistory} from 'react-router-dom';
import {Button} from 'semantic-ui-react';

import {Modal} from '@/components';
import {withDrawOne, allWithDraw} from './handlers';

import './index.scss';

interface Props {
  id: number;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExhibitButtons: React.FC<Props> = ({id, loading, setLoading}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [allWithdrawModal, setAllWithdrawModal] = useState(false);

  return (
    <div className="exhibit-buttons">
      <Button
        type="button"
        className="salmon"
        onClick={() => setWithdrawModal(true)}
      >
        出品中のものを取り下げる
      </Button>
      <Button
        type="button"
        className="salmon"
        onClick={() => setAllWithdrawModal(true)}
      >
        在庫を含めてすべて取り下げる
      </Button>
      <Button
        type="button"
        onClick={() => {
          history.goBack();
        }}
      >
        前のページに戻る
      </Button>

      {/* 取り下げる */}
      <Modal.Normal
        isModal={withdrawModal}
        handleCloseModal={() => {
          setWithdrawModal(false);
        }}
        onClick={async () => {
          await withDrawOne(id, dispatch, setLoading, setWithdrawModal);
        }}
        size="sm"
        loading={loading}
        submitColor="main"
        submitText="取り下げる"
        title="取り下げてもよろしいですか?"
      />

      <Modal.Normal
        isModal={allWithdrawModal}
        handleCloseModal={() => {
          setAllWithdrawModal(false);
        }}
        onClick={async () => {
          await allWithDraw(id, dispatch, setLoading, setAllWithdrawModal);
        }}
        size="sm"
        loading={loading}
        submitColor="delete"
        submitText="取り下げる"
        title="出品をすべて取り下げますか?"
      >
        <div className="all-withdraw-modal">
          <p>在庫を含めてすべて（繰り返し回数分すべて）、取り下げます。</p>
          <p style={{color: '#d44343'}}>
            (すでに入札されている場合に取り下げると、マイナス評価を受けますので、ご注意ください。)
          </p>
        </div>
      </Modal.Normal>
    </div>
  );
};

export default ExhibitButtons;
