import React from 'react';
import {Checkbox, CheckboxProps} from 'semantic-ui-react';

const SemanticCheckbox: React.FC<CheckboxProps> = (props) => {
  const {field, ...rest} = props;

  return (
    <div className="a__checkbox">
      <Checkbox {...field} {...rest} />
    </div>
  );
};

export default SemanticCheckbox;
