import React from 'react';
import {Button as SButton, Icon} from 'semantic-ui-react';
import classNames from 'classnames';

import {Props} from './types';

import './index.scss';

const Button: React.FC<Props> = (props) => {
  return (
    <SButton
      positive={props.color === 'main'}
      type={props.type ? props.type : 'button'}
      fluid={props.fluid}
      loading={props.loading}
      disabled={props.disabled}
      className={classNames({
        edit: props.color === 'edit',
        delete: props.color === 'delete',
        disabled: props.color === 'disabled',
      })}
      style={props.style ? props.style : {}}
      onClick={props.onClick}
    >
      {props.icon ? <Icon name={props.icon} /> : null}
      {props.children}
    </SButton>
  );
};

export default Button;
