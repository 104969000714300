import React, {useEffect} from 'react';
import {
  Switch,
  Route,
  useHistory,
  Redirect,
  useLocation,
} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '@/app/rootReducer';
import {Layouts} from '@/components';
import paths from '@/config/paths';

import {SignInMain} from '@/templates/SignIn/Main';
import {MatchPathRedirect} from '@/templates/MatchPathRedirect/Main';
import {NotFoundMain} from '@/templates/NotFound/Main';
import {SalesMain} from '@/templates/Sales/Main';
import {StocksMain} from '@/templates/Stocks/Main';
import {StocksDetail} from '@/templates/Stocks/Detail';
import {StocksCreate} from '@/templates/Stocks/Create';
import {ShippingCosts} from '@/templates/ShippingCosts/Main';

import {useAppState} from './useAppState';

import './index.scss';

export type AppRoute = {
  path: string;
  component: any;
  exact: boolean;
};

const Routes: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const me = useSelector((state: RootState) => state.me);
  const {isLoading, isAuthed} = useAppState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (isAuthed && window.location.pathname === '/') {
      history.replace(paths.sales.main);
    }
  }, [isAuthed, history]);

  if (isLoading) return null;

  if (!isAuthed) {
    return (
      <div className="a__routes">
        <div className="b__before">
          <Switch>
            <Route path={paths.signIn.main} component={SignInMain} exact />
            <Route
              path={paths.shippingCosts.main}
              component={MatchPathRedirect}
              exact
            />
            <Route path="*" component={NotFoundMain} />
          </Switch>
        </div>
      </div>
    );
  }

  return (
    <div className="a__routes">
      <div className="b__after" id="top">
        <div className="b__sidebar">
          <Layouts.SideBar.Main />
        </div>
        <div className="b__header">
          <Layouts.Header.After history={history} me={me} />
        </div>
        <div className="b__main">
          <Switch>
            <Route path={paths.sales.main} component={SalesMain} exact />
            <Route path={paths.stocks.main} component={StocksMain} exact />
            <Route path={paths.stocks.create} component={StocksCreate} exact />
            <Route path={paths.stocks.detail} component={StocksDetail} exact />
            <Route
              path={paths.shippingCosts.main}
              component={ShippingCosts}
              exact
            />
            <Route
              path={paths.signIn.main}
              render={() => <Redirect to={paths.sales.main} />}
            />

            <Route path="*" component={NotFoundMain} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Routes;
