import React from 'react';
import classNames from 'classnames';

import Base from './../_Base';
import {Props} from './types';

import './index.scss';

const BlockInput: React.FC<Props> = (props) => {
  return (
    <div
      className={classNames('a__button-round', [
        props.className,
        {disabled: props.disabled},
      ])}
    >
      <Base
        loading={props.loading}
        disabled={props.disabled}
        type={props.type}
        fluid={props.fluid}
        color={props.color}
        onClick={props.onClick}
      >
        {props.children}
      </Base>
    </div>
  );
};

export default BlockInput;
