/* 出品中 */
export const DISPLAYING = 0;

/* 取引完了 */
export const DONE = 1;

/* 出品期限切れ */
export const EXPIRED = 2;

/* 出品取り消し */
export const CANCELED = 3;

/* 管理者による出品取り消し */
export const BANNED = 4;

/** 決済エラー */
export const ERROR = 5;
