import React from 'react';
import {useDropzone, DropzoneOptions} from 'react-dropzone';
import {useField} from 'formik';
import Compressor from 'compressorjs';

const Dropzone: React.FC<any> = (props) => {
  const [field, meta, helpers] = useField(props);

  const onDrop: DropzoneOptions['onDrop'] = (acceptedFiles) => {
    const promises = acceptedFiles.map(
      (file) =>
        new Promise((resolve) => {
          const reader = new FileReader();

          reader.onload = ({target}) => {
            if (!target) {
              return;
            }

            const {result} = target;

            resolve(result);
          };
          new Compressor(file, {
            quality: 0.3,
            maxWidth: 1000,
            success(result) {
              reader.readAsDataURL(result);
            },
          });
        })
    );

    Promise.all(promises).then((images) => {
      const {value} = meta;

      helpers.setValue(
        [...value, ...(images as string[])].filter((_, index) => index < 10)
      );
    });
  };

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: [
      'image/apng',
      'image/bmp',
      'image/gif',
      'image/x-icon',
      'image/jpeg',
      'image/png',
      'image/svg+xml',
      'image/tiff',
      'image/webp',
    ],
  });

  return (
    <div className="a__dropzone">
      <div {...getRootProps()} className="b__dropzone">
        <input {...getInputProps()} />
        <p>
          ドラッグ＆ドロップ
          <br />
          またはクリックで画像を追加（最大10枚）
        </p>
      </div>
      <p className="b__dropzone__paragraph">
        ※アップロード可能拡張子 .apng, .bmp, .gif, .ico, .jpg, .jpeg, .png,
        .svg, .tiff, .webp
      </p>
      <ul className="b__preview">
        {meta.value.map((src: string, index: number) => (
          <li key={index}>
            <div className="b__preview__wrapper">
              <img src={src} />
              <button
                type="button"
                onClick={() => {
                  const data = meta.value.filter(
                    (_: any, i: number) => i !== index
                  );
                  helpers.setValue(data);
                }}
              >
                削除
              </button>
            </div>
          </li>
        ))}
      </ul>
      {meta.error && <p className="form__error">{meta.error}</p>}
    </div>
  );
};

export default Dropzone;
