import React from 'react';
import classNames from 'classnames';
import {
  useTable,
  useBlockLayout,
  useResizeColumns,
  useSortBy,
} from 'react-table';
import Loader from '../../Loader';

import {Props} from './types';

import './index.scss';

const Table: React.FC<Props> = (props) => {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 50,
      width: 150,
      maxWidth: 800,
    }),
    []
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable(
      {
        columns: props.columns,
        data: props.data,
        defaultColumn,
      },
      useBlockLayout,
      useResizeColumns,
      useSortBy
    );

  return (
    <div
      {...getTableProps()}
      className={classNames({table: true, loading: props.loading})}
    >
      <Loader className="loader" active={props.loading} />

      <div className={classNames({loading: props.loading})}>
        {headerGroups.map((headerGroup, i) => (
          <div {...headerGroup.getHeaderGroupProps()} className="thead" key={i}>
            {headerGroup.headers.map((column: any, i) => (
              <div
                key={i}
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className="c__th th"
              >
                {column.render('Header')}
                <div
                  {...column.getResizerProps()}
                  className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                />
                {props.canUseSortBy && column.canSort && column.isSorted
                  ? column.isSortedDesc
                    ? ' 🔽'
                    : ' 🔼'
                  : null}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div
        {...getTableBodyProps()}
        className={classNames('tbody', {loading: props.loading})}
      >
        {/* 0件メッセージ */}
        {!props.loading && rows.length === 0 ? (
          <div className="empty-message">
            {props.emptyMessage || 'データがありません'}
          </div>
        ) : null}

        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <div {...row.getRowProps()} className="tr" key={i}>
              {row.cells.map((cell, i) => {
                return (
                  <div {...cell.getCellProps()} className="c__td td" key={i}>
                    {cell.render('Cell')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Table;
