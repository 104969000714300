import React from 'react';

import _Base from './../_Base';
import validations from '@/lib/validations';

import {Button, Form} from '@/components';
import {Props} from './types';
import {Formik, Field, FormikProps, FormikValues} from 'formik';

import './index.scss';

const Chat = (props: Props) => {
  return (
    <_Base
      title={'質問・コメント'}
      isModal={props.isModal}
      handleCloseModal={props.setIsModal}
      size="md"
    >
      <div className="comment-modal">
        <div className="comment-modal__message">
          ※この出品について聞きたいことやコメントを送りましょう。
        </div>
        <Formik
          initialValues={{
            message: '',
          }}
          onSubmit={({message}, actions) => {
            actions.setSubmitting(true);
            props.onSubmit(message);
            actions.setSubmitting(false);
          }}
          validate={validations.sales.chat}
          enableReinitialize
        >
          {(formProps: FormikProps<FormikValues>) => (
            <form className="comment-modal__form">
              <Field name="message" maxLength={500} component={Form.TextArea} />
              {formProps.errors.message && formProps.touched.message && (
                <div className="form__error">{formProps.errors.message}</div>
              )}
              <p>(最大500文字)</p>

              <Button.Block
                color="main"
                fluid
                loading={formProps.isSubmitting}
                disabled={formProps.isSubmitting}
                onClick={() => formProps.handleSubmit()}
              >
                書き込む
              </Button.Block>
            </form>
          )}
        </Formik>
      </div>
    </_Base>
  );
};
export default Chat;
