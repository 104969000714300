import {default as local} from './local';
import {default as dev} from './dev';
import {default as stg} from './stg';
import {default as prd} from './prd';

const env = {
  local,
  dev,
  stg,
  prd,
};

export default env;
