import React from 'react';
import {Dropdown} from 'semantic-ui-react';

import paths from '@/config/paths';
import utils from '@/lib/utils';

import {Props} from './types';

import './index.scss';

const replaceTofu = (str: string) => {
  if (!str) return '';

  if (!str.trim()) {
    return '■'.repeat(str.length);
  }

  return str;
};

const AfterHeader: React.FC<Props> = (props) => {
  return (
    <div className="a__header-after">
      <Dropdown
        text={replaceTofu(props.me.displayName)}
        direction="left"
        className="b__user"
      >
        <Dropdown.Menu>
          <Dropdown.Item
            text="ログアウト"
            onClick={async () => {
              await utils.amplify.signOut();
              window.location.href = paths.signIn.main;
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AfterHeader;
