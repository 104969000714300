import {combineReducers} from '@reduxjs/toolkit';

import meSlice from '@/redux/meSlice';
import salesSlice from '@/redux/salesSlice';
import stocksSlice from '@/redux/stockSlice';
import shippingCostsSlice from '@/redux/shippingCostsSlice';

const rootReducer = combineReducers({
  me: meSlice,
  sales: salesSlice,
  stocks: stocksSlice,
  shippingCosts: shippingCostsSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
