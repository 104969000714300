import React from 'react';
import {Input as SemanticUiInput, Label, Icon} from 'semantic-ui-react';
import classNames from 'classnames';

import {Props} from './types';

import './index.scss';

const Input: React.FC<Props> = (props) => {
  return (
    <>
      <SemanticUiInput
        value={props.field.value}
        onChange={(e: any) => {
          props.form.setFieldValue(props.field.name, e.target.value);
        }}
        className={props.className}
        style={props.style}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        disabled={props.disabled}
        field={props.field}
        form={props.form}
        maxLength={props.maxLength}
        fluid={props.fluid}
        type={props.type}
        onKeyDown={(e: any) => {
          // Enterを押下した時の処理
          if (e.keyCode === 13) {
            props.search?.onKeyDown(e.target.value);
          }
        }}
      />
      {props.search ? (
        <div
          className={classNames('b__search-result', {
            gc__hidden: props.search.words.length === 0,
          })}
        >
          <div className="c__label">{`検索条件`}</div>
          {props.search.words.map((word: any, searchWordIndex: any) => {
            return (
              <Label
                key={`${word}-${searchWordIndex}`}
                onClick={() => {
                  props.search?.removeWords(word);
                }}
                className="w__label"
              >
                {word}
                <Icon name="close" />
              </Label>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default Input;
