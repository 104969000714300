import React from 'react';
import classNames from 'classnames';

const OPTION = {
  required: 'required',
  optional: 'optional',
} as const;

type OPTION_TYPES = typeof OPTION[keyof typeof OPTION];

interface Props {
  text?: string;
  option: OPTION_TYPES;
}

const Label: React.FC<Props> = ({text, option}) => {
  if (text) {
    return (
      <div className="rating-label">
        <label>{text}</label>

        <div
          className={classNames('rating-label__optional', {
            'is-optional': option === OPTION.optional,
          })}
        >
          <span>{option === OPTION.optional ? '任意' : '必須'}</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames('rating-label__optional', {
        'is-optional': option === OPTION.optional,
      })}
    >
      <span>{option === OPTION.optional ? '任意' : '必須'}</span>
    </div>
  );
};

export default Label;
