import React, {useState} from 'react';
import {isEmpty} from 'lodash';
import {FormikProps, FormikValues} from 'formik';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Button} from 'semantic-ui-react';

import {Modal} from '@/components';
import {allWithDraw, restartAutoStock, stopAutoStock} from './handlers';

import './index.scss';

interface Props {
  id: number;
  formProps: FormikProps<FormikValues>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isRepeat: boolean;
  updateModal: boolean;
  setUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const StockButtons: React.FC<Props> = ({
  id,
  formProps,
  loading,
  setLoading,
  isRepeat,
  updateModal,
  setUpdateModal,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [stopModal, setStopModal] = useState(false);
  const [restartModal, setRestartModal] = useState(false);
  const [allWithdrawModal, setAllWithdrawModal] = useState(false);

  return (
    <div className="stock-buttons">
      <Button
        type="button"
        className="orange"
        onClick={async () => {
          const v = await formProps.validateForm();
          if (isEmpty(v)) {
            setUpdateModal(true);
          } else {
            if (document.getElementsByClassName('form__error')) {
              const errors = document.getElementsByClassName(
                'form__error'
              ) as HTMLCollectionOf<HTMLElement>;

              errors[0].scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'nearest',
              });
            }
          }
        }}
      >
        変更内容を保存する
      </Button>
      {isRepeat ? (
        <Button
          type="button"
          className="orange"
          onClick={() => {
            setStopModal(true);
          }}
        >
          自動出品を停止する
        </Button>
      ) : (
        <Button
          type="button"
          className="orange"
          onClick={() => {
            setRestartModal(true);
          }}
        >
          自動出品を再開する
        </Button>
      )}

      <Button
        type="button"
        className="salmon"
        onClick={() => {
          setAllWithdrawModal(true);
        }}
      >
        在庫を含めてすべて取り下げる
      </Button>

      <Button
        type="button"
        onClick={() => {
          history.goBack();
        }}
      >
        前のページに戻る
      </Button>

      {/* 変更内容を保存する */}
      <Modal.Normal
        isModal={updateModal}
        handleCloseModal={() => {
          setUpdateModal(false);
        }}
        onClick={() => formProps.handleSubmit()}
        size="sm"
        loading={formProps.isSubmitting}
        submitColor="main"
        submitText="変更を保存する"
        title="変更内容を保存しますか?"
      >
        <div className="update-modal">
          <p>変更内容が保存されます</p>
          <p style={{color: '#d44343'}}>
            (出品中の商品がある場合は、次の出品から変更が反映されます。)
          </p>
        </div>
      </Modal.Normal>

      {/* 自動出品を停止する */}
      <Modal.Normal
        isModal={stopModal}
        handleCloseModal={() => {
          setStopModal(false);
        }}
        onClick={async () => {
          await stopAutoStock(id, dispatch, setLoading, setStopModal);
        }}
        size="sm"
        loading={loading}
        submitColor="delete"
        submitText="自動出品を停止する"
        title="自動出品を停止しますか?"
      >
        <div className="stop-modal">
          <p>変更内容が保存され、自動出品が停止されます。</p>
        </div>
      </Modal.Normal>

      {/* 自動出品を再開する */}
      <Modal.Normal
        isModal={restartModal}
        handleCloseModal={() => {
          setRestartModal(false);
        }}
        onClick={async () => {
          await restartAutoStock(id, dispatch, setLoading, setRestartModal);
        }}
        size="sm"
        loading={loading}
        submitColor="main"
        submitText="自動出品を再開する"
        title="自動出品を再開しますか?"
      >
        <div className="restart-modal">
          <p>変更内容が保存され、自動出品が再開されます。</p>
        </div>
      </Modal.Normal>

      {/* 在庫を含めてすべて取り下げる */}
      <Modal.Normal
        isModal={allWithdrawModal}
        handleCloseModal={() => {
          setAllWithdrawModal(false);
        }}
        onClick={async () => {
          await allWithDraw(id, dispatch, setLoading, setAllWithdrawModal);
        }}
        size="sm"
        loading={loading}
        submitColor="delete"
        submitText="取り下げる"
        title="出品をすべて取り下げますか?"
      >
        <div className="all-withdraw-modal">
          <p>在庫を含めてすべて（繰り返し回数分すべて）、取り下げます。</p>
          <p style={{color: '#d44343'}}>
            (すでに入札されている場合に取り下げると、マイナス評価を受けますので、ご注意ください。)
          </p>
        </div>
      </Modal.Normal>
    </div>
  );
};

export default StockButtons;
