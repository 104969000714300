import _ from 'lodash';

import {Mapping} from '@/config/mappings/types';

/**
 * マッピングデータからlabelを取得する
 * @param mappings 対象となるマッピングデータ
 * @param value マッピングを行う値
 */
export const getLabel = (mappings: Mapping[], value?: Mapping['value']) => {
  const mapping = _.find(mappings, (m) => m.value === value);

  if (mapping) {
    return mapping.label;
  } else {
    return '';
  }
};
