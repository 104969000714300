import * as _utils from './_utils';

const stg: () => Env = () => {
  const envcode: Envcode = 'stg';

  return {
    NAME: 'ステージ環境',
    COGNITO_REGION: 'ap-northeast-1',
    COGNITO_USER_POOL_ID: 'ap-northeast-1_tEOvXpfPz',
    COGNITO_CLIENT_ID: '252d8tdseubl0rmujtanfeg47d',
    COGNITO_DOMAIN:
      'https://fishsale-stg.auth.ap-northeast-1.amazoncognito.com',
    COGNITO_REDIRECT_URI: 'https://stg.vendor.vegetablesale.jp/signin',
    API_GO_ENDPOINT: 'https://stg.api.vegetablesale.jp',
    SITE_TYPE: 'vegetable',
    ENVCODE: envcode,
    TITLE: _utils.getName(envcode),
    WEB_APP_HOST: 'https://stg.vegetablesale.jp',
  };
};

export default stg();
