import notify from '@/migration/notification';
import {withDraw} from '@/redux/stockSlice';
import moment from 'moment';
import paths from '@/config/paths';
import {mergeDate} from '@/lib/utils/date';
import {updateStocks} from '@/api/stocks';

/** 日付フォーマット */
export const format = (base: string, format?: string) => {
  return moment(base).format(format);
};

export const submit = async (
  stockId: number,
  value: any,
  actions: any,
  history: any,
  setModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    actions.setSubmitting(true);

    const params = {
      ...value,
      isRepeat: true,
      count: 0,
      isDummy: false,
      fishingSpot: value.fishingSpot ? parseInt(value.fishingSpot) : 0,
      closeBidMin: value.closeBidMin * 60,
      capacity: parseInt(value.capacity, 10),
      weight: value.weight ? parseInt(value.weight, 10) : 0,
      size: parseInt(value.size, 10),
      startPrice: parseInt(value.startPrice, 10),
      fixPrice: value.fixPrice ? parseInt(value.fixPrice, 10) : 0,
      fishingDay: mergeDate(
        value.fishingDate.year,
        value.fishingDate.month,
        value.fishingDate.day
      ),
      repeatLimitDate: mergeDate(
        value.repeatLimitDate.year,
        value.repeatLimitDate.month,
        value.repeatLimitDate.day
      ),
    };

    if (!value.weight) {
      delete params.weight;
    }

    if (!value.fixPrice) {
      delete params.fixPrice;
    }

    await updateStocks(stockId, params);

    notify('在庫の変更内容を保存しました。', 'success');
    history.push(paths.stocks.main);
  } catch (error: any) {
    notify(error.message, 'error');
  } finally {
    actions.setSubmitting(false);
    setModal(false);
  }
};

/** 自動出品を停止する */
export const stopAutoStock = async (
  stockId: number,
  dispatch: React.Dispatch<any>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoading(true);

    dispatch(
      withDraw({
        stockId,
        isRepeat: false,
        isCanceled: false,
      })
    );

    notify('自動出品を停止しました。', 'success');
  } catch (error: any) {
    notify(error.message, 'error');
  } finally {
    setLoading(false);
    setModal(false);
  }
};

/** 自動出品を再開する */
export const restartAutoStock = async (
  stockId: number,
  dispatch: React.Dispatch<any>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoading(true);

    dispatch(
      withDraw({
        stockId,
        isRepeat: true,
        isCanceled: false,
      })
    );

    notify('自動出品を再開しました。', 'success');
  } catch (error: any) {
    notify(error.message, 'error');
  } finally {
    setLoading(false);
    setModal(false);
  }
};

/** すべて取り下げる */
export const allWithDraw = async (
  stockId: number,
  dispatch: React.Dispatch<any>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setAllWithdrawModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoading(true);

    dispatch(
      withDraw({
        stockId,
        isRepeat: false,
        isCanceled: true,
      })
    );

    notify('自動再出品を停止し、出品中の商品を取り下げました', 'success');
  } catch (error: any) {
    notify(error.message, 'error');
  } finally {
    setLoading(false);
    setAllWithdrawModal(false);
  }
};
