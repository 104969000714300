import React from 'react';
import {Calendar} from 'primereact/calendar';
import classNames from 'classnames';

import {ja} from './locale';
import {DatePickerProps} from './types';

import './index.scss';

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {fluid, dark, field, ...restProps} = props;

  return (
    <div
      className={classNames('a__date-picker', {
        'p-fluid': fluid,
        dark,
      })}
    >
      <Calendar
        showWeek={false}
        dateFormat="yy/mm/dd"
        locale={ja}
        {...field}
        {...restProps}
      />
    </div>
  );
};

export default DatePicker;
