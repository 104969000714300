import {Checkbox, Dropdown, Input, Label} from '@/components/Form';
import {
  cultivationMethod,
  days,
  fishingSpots,
  genetic,
  harvestTiming,
  jas,
  months,
  pesticides,
  pesticidesAdditionalInfo,
  saveMethod,
  soil,
  soilAdditionalInfo,
  years,
} from '@/config/constants/options';
import {now} from '@/lib/utils/date';
import {Field, FormikProps, FormikValues} from 'formik';

interface Props {
  formProps: FormikProps<FormikValues>;
  exhibit?: boolean;
}

export const Inputs = ({formProps, exhibit}: Props) => {
  return (
    <>
      {/* カテゴリー */}
      {/* <div className="b__field">
        <Label text="カテゴリー" required />
        <Field
          name="category"
          component={Dropdown}
          fluid
          options={[
            ...Object.keys(VEGETABLE_CATEGORY).map((key, i) => {
              return {
                key: i,
                value: VEGETABLE_CATEGORY[key],
                name: VEGETABLE_CATEGORY[key],
                text: VEGETABLE_CATEGORY[key],
              };
            }),
          ]}
          selection
          placeholder="選択してください"
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
          disabled={exhibit}
        />
        {formProps.errors['category'] !== undefined ? (
          <a className="form__error">{formProps.errors['category']}</a>
        ) : null}
      </div> */}

      {/* 品種 */}
      <div className="b__field">
        <Label text="品種" optional />
        <Field
          name="breed"
          component={Input.Default}
          maxLength="50"
          placeholder=""
          disabled={exhibit}
        />
        {/* <Field
          name="breed"
          component={Dropdown}
          fluid
          options={[
            ...VEGETABLE_DB.filter(
              (f) => f.category === formProps.values['category']
            ).map((item, i) => {
              return {
                key: i,
                value: item['name'],
                name: item['name'],
                text: item['name'],
              };
            }),
          ]}
          selection
          placeholder="選択してください"
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
          disabled={exhibit}
        /> */}
        {formProps.errors['breed'] !== undefined ? (
          <a className="form__error">{formProps.errors['breed']}</a>
        ) : null}
      </div>

      {/* 等級 */}
      {/* <div className="b__field">
        <Label text="等級" optional />
        <Field
          name="grade"
          component={Dropdown}
          fluid
          options={[
            {
              key: 99999,
              value: '',
              name: '',
              text: '選択してください',
            },
            ...(() => {
              const data = VEGETABLE_DB.find(
                (f) => f.name === formProps.values['breed']
              );
              if (!data) return [];

              return data['grade'].map((val, i) => {
                return {
                  key: i,
                  value: val,
                  name: val,
                  text: val,
                };
              });
            })(),
          ]}
          selection
          placeholder="選択してください"
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
          disabled={exhibit}
        />
        {formProps.errors['grade'] !== undefined ? (
          <a className="form__error">{formProps.errors['grade']}</a>
        ) : null}
      </div> */}

      {/* 階級 */}
      {/* <div className="b__field">
        <Label text="階級" optional />
        <Field
          name="rank"
          component={Dropdown}
          fluid
          options={[
            {
              key: 99999,
              value: '',
              name: '',
              text: '選択してください',
            },
            ...(() => {
              const data = VEGETABLE_DB.find(
                (f) => f.name === formProps.values['breed']
              );
              if (!data) return [];

              if (Array.isArray(data['rank']))
                return data['rank'].map((val, i) => {
                  return {
                    key: i,
                    value: val,
                    name: val,
                    text: val,
                  };
                });

              if (!Array.isArray(data['rank'][formProps.values['grade']])) {
                return [];
              }

              return data['rank'][formProps.values['grade']].map((val, i) => {
                return {
                  key: i,
                  value: val,
                  name: val,
                  text: val,
                };
              });
            })(),
          ]}
          selection
          placeholder="選択してください"
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
          disabled={(() => {
            if (exhibit) return exhibit;

            const data = VEGETABLE_DB.find(
              (f) => f.name === formProps.values['breed']
            );
            if (data) {
              // グレードを持っていなければ選択できるように
              if (data.grade.length === 0) return false;
            }

            console.log(formProps.values['grade']);

            if (formProps.values['grade'] === '') return true;

            return exhibit;
          })()}
        />
        {formProps.errors['rank'] !== undefined ? (
          <a className="form__error">{formProps.errors['rank']}</a>
        ) : null}
      </div> */}

      {/* サイズ */}
      <div className="b__field">
        <Label text="最小サイズ(最大50文字)" optional />
        <Field
          name="size"
          component={Input.Default}
          maxLength="50"
          placeholder=""
          disabled={exhibit}
        />
        {formProps.errors['size'] !== undefined ? (
          <a className="form__error">{formProps.errors['size']}</a>
        ) : null}
      </div>

      <div className="b__field__m0">
        <Field
          name="isUnevenness"
          label="不揃い"
          component={Checkbox}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.checked);
          }}
          disabled={exhibit}
        />
      </div>

      {/* 重量 */}
      <div className="b__field">
        <Label text="重量 (最大10,000,000g)" optional />
        <Field
          name="weight"
          component={Input.Default}
          maxLength="8"
          placeholder="例) 1500"
          label={{basic: true, content: 'g以上'}}
          labelPosition="right"
          disabled={exhibit}
        />
        {formProps.errors['weight'] !== undefined ? (
          <a className="form__error">{formProps.errors['weight']}</a>
        ) : null}
      </div>

      <div className="b__field">
        <Field
          name="weightAdditionalInfo"
          component={Input.Default}
          maxLength="50"
          placeholder="備考"
          disabled={exhibit}
        />
        <div className="b__paragraph">(最大50文字)</div>
      </div>

      {/* 糖度 */}
      <div className="b__field">
        <Label text="糖度 (最大50文字)" optional />
        <Field
          name="average_sugar_content"
          component={Input.Default}
          maxLength="50"
          placeholder="例) 15度"
          disabled={exhibit}
        />
        {formProps.errors['average_sugar_content'] !== undefined ? (
          <a className="form__error">
            {formProps.errors['average_sugar_content']}
          </a>
        ) : null}
      </div>

      {/* 入り数 */}
      <div className="b__field">
        <Label text="入り数 (最大50文字)" optional />
        <Field
          name="input_number"
          component={Input.Default}
          maxLength="50"
          placeholder="〇個以上"
          disabled={exhibit}
        />
        {formProps.errors['input_number'] !== undefined ? (
          <a className="form__error">{formProps.errors['input_number']}</a>
        ) : null}
      </div>

      <div className="b__field__m0">
        <Field
          name="with_defects"
          label="訳あり"
          component={Checkbox}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.checked);
          }}
          disabled={exhibit}
        />
      </div>

      {/* 生産者 */}
      <div className="b__field">
        <Label text="生産者 (最大50文字)" optional />
        <Field
          name="producer"
          component={Input.Default}
          maxLength="50"
          placeholder="山田農園"
          disabled={exhibit}
        />
        {formProps.errors['producer'] !== undefined ? (
          <a className="form__error">{formProps.errors['producer']}</a>
        ) : null}
      </div>

      {/* 産地 */}
      <div className="b__field">
        <Label text="産地" required />
        <Field
          name="origin"
          component={Dropdown}
          fluid
          options={fishingSpots}
          selection
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
          placeholder="選択してください"
          disabled={exhibit}
        />
      </div>

      <div className="b__field">
        <Field
          name="originAdditionalInfo"
          component={Input.Default}
          maxLength="50"
          placeholder="詳細ポイント"
          disabled={exhibit}
        />
        {formProps.values.breed === '生しいたけ' ? (
          <p style={{marginTop: '.25em', color: 'red'}}>
            ※生しいたけを出品する場合「菌床」、「原木」の表示をしてください
          </p>
        ) : null}
      </div>

      {/* 収穫日 */}
      <div className="b__field">
        <Label text="収穫日" optional />
        <div className="b__field__row">
          <div className="b__field__column">
            <Field
              name="fishingDate.year"
              component={Dropdown}
              fluid
              selection
              placeholder={now('YYYY')}
              options={years}
              onChange={(_: any, field: any) => {
                formProps.setFieldValue(field.name, field.value);
              }}
              disabled={exhibit}
            />
            <span>年</span>
          </div>
          <div className="b__field__column">
            <Field
              name="fishingDate.month"
              component={Dropdown}
              fluid
              selection
              placeholder={now('M')}
              options={months}
              onChange={(_: any, field: any) => {
                formProps.setFieldValue(field.name, field.value);
              }}
              disabled={exhibit}
            />
            <span>月</span>
          </div>
          <div className="b__field__column">
            <Field
              name="fishingDate.day"
              component={Dropdown}
              fluid
              selection
              placeholder={now('D')}
              options={days}
              onChange={(_: any, field: any) => {
                formProps.setFieldValue(field.name, field.value);
              }}
              disabled={exhibit}
            />
            <span>日</span>
          </div>
        </div>
      </div>

      {/* 収穫タイミング */}
      <div className="b__field">
        <Label text="収穫タイミング" optional />
        <Field
          name="harvestTiming"
          component={Dropdown}
          fluid
          options={[
            {
              key: '0',
              value: null,
              name: '',
              text: '未設定',
            },
            ...harvestTiming,
          ]}
          selection
          placeholder="選択してください"
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
          disabled={exhibit}
        />
        {formProps.errors['harvestTiming'] !== undefined ? (
          <a className="form__error">{formProps.errors['harvestTiming']}</a>
        ) : null}
      </div>

      {/* 出品者側保管方法 */}
      <div className="b__field">
        <Label text="出品者側保管方法" />
        <Field
          name="saveMethodSeller"
          component={Dropdown}
          fluid
          options={[
            {
              key: '0',
              value: null,
              name: '',
              text: '未設定',
            },
            ...saveMethod,
          ]}
          selection
          placeholder="選択してください"
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
          disabled={exhibit}
        />
        {formProps.errors['saveMethodSeller'] !== undefined ? (
          <a className="form__error">{formProps.errors['saveMethodSeller']}</a>
        ) : null}
      </div>

      {/* ご家庭での推奨保管方法 */}
      <div className="b__field">
        <Label text="ご家庭での推奨保管方法" optional />
        <Field
          name="saveMethodUser"
          component={Dropdown}
          fluid
          options={[
            {
              key: '0',
              value: null,
              name: '',
              text: '未設定',
            },
            ...saveMethod,
          ]}
          selection
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
          placeholder="選択してください"
          disabled={exhibit}
        />
      </div>

      <div className="b__field">
        <Field
          name="saveMethodUserAdditionalInfo"
          component={Input.Default}
          maxLength="50"
          placeholder="お召し上がりになる約1時間前に冷蔵庫で冷やして など"
          disabled={exhibit}
        />
      </div>

      {/* 栽培方法 */}
      <div className="b__field">
        <Label text="栽培方法" optional />
        <Field
          name="cultivationMethod"
          component={Dropdown}
          fluid
          options={[
            {
              key: '0',
              value: null,
              name: '',
              text: '未設定',
            },
            ...cultivationMethod,
          ]}
          selection
          placeholder="選択してください"
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
          disabled={exhibit}
        />
        {formProps.errors['cultivation_method'] !== undefined ? (
          <a className="form__error">
            {formProps.errors['cultivation_method']}
          </a>
        ) : null}
      </div>

      {/* 土壌 */}
      <div className="b__field">
        <Label text="土壌" optional />
        <Field
          name="soil"
          component={Dropdown}
          fluid
          options={[
            {
              key: '0',
              value: null,
              name: '',
              text: '未設定',
            },
            ...soil,
          ]}
          selection
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
          placeholder="選択してください"
          disabled={exhibit}
        />
        {formProps.values['soil'] === 4 && (
          <div className="stack">
            {soilAdditionalInfo.map((e, i) => (
              <Field
                key={e.key}
                name={`soilAdditionalInfo[${i}]`}
                label={e.text}
                component={Checkbox}
                onChange={(_: any, field: any) => {
                  formProps.setFieldValue(field.name, field.checked);
                }}
                disabled={exhibit}
                checked={(formProps.values.soilAdditionalInfo ?? [])[i]}
              />
            ))}
          </div>
        )}
      </div>

      {/* 農薬 */}
      <div className="b__field">
        <Label text="農薬" optional />
        <Field
          name="pesticides"
          component={Dropdown}
          fluid
          options={[
            {
              key: '0',
              value: null,
              name: '',
              text: '未設定',
            },
            ...pesticides,
          ]}
          selection
          placeholder="選択してください"
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
          disabled={exhibit}
        />
        {formProps.errors['pesticides'] !== undefined ? (
          <a className="form__error">{formProps.errors['pesticides']}</a>
        ) : null}
        {formProps.values['pesticides'] === 2 && (
          <Field
            name="pesticidesAdditionalInfo[0]"
            component={Input.Default}
            maxLength="50"
            placeholder="節減対象農薬を節減「当地比〇割減、〇〇地域比○割減）」"
            disabled={exhibit}
          />
        )}
        {formProps.values['pesticides'] === 3 && (
          <div className="stack">
            {pesticidesAdditionalInfo.map((e, i) => (
              <Field
                key={e.key}
                name={`pesticidesAdditionalInfo[${i}]`}
                label={e.text}
                component={Checkbox}
                onChange={(_: any, field: any) => {
                  formProps.setFieldValue(field.name, field.checked);
                }}
                checked={(formProps.values.pesticidesAdditionalInfo ?? [])[i]}
                disabled={exhibit}
              />
            ))}
          </div>
        )}
      </div>

      {/* 遺伝子組み換え */}
      <div className="b__field">
        <Label text="遺伝子組み換え" optional />
        <Field
          name="genetic"
          component={Dropdown}
          fluid
          options={[
            {
              key: '0',
              value: null,
              name: '',
              text: '未設定',
            },
            ...genetic,
          ]}
          selection
          placeholder="選択してください"
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
          disabled={exhibit}
        />
        {formProps.errors['genetic'] !== undefined ? (
          <a className="form__error">{formProps.errors['genetic']}</a>
        ) : null}
      </div>

      {/* 有機JAS認定 */}
      <div className="b__field">
        <Label text="有機JAS認定" optional />
        {/* <Field
          name="jas"
          component={Dropdown}
          fluid
          options={[
            {
              key: '0',
              value: null,
              name: '',
              text: '未設定',
            },
            ...jas,
          ]}
          selection
          placeholder="選択してください"
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
          disabled={exhibit}
        /> */}
        <div className="stack">
          {jas.map((d, i) => {
            return (
              <Field
                name={`jas[${i}]`}
                label={d.text}
                component={Checkbox}
                onChange={(_: any, field: any) => {
                  formProps.setFieldValue(field.name, field.value);
                }}
                disabled={exhibit}
              />
            );
          })}
        </div>
        {formProps.errors['jas'] !== undefined ? (
          <a className="form__error">{formProps.errors['jas']}</a>
        ) : null}
      </div>

      {/* 個別的義務表示事項 (最大50文字) */}
      <div className="b__field">
        <Label text="個別的義務表示事項 (最大50文字)" optional />
        <Field
          name="individualObligations"
          component={Input.Default}
          maxLength="50"
          placeholder="アレルゲン、消費期限又は賞味期限、添加物など"
          disabled={exhibit}
        />
        {formProps.errors['individual_obligations'] !== undefined ? (
          <a className="form__error">
            {formProps.errors['individual_obligations']}
          </a>
        ) : null}
      </div>

      {/* グローバルGAP */}
      <div className="b__field">
        <Label text="グローバルGAP" optional />
        <Field
          name="globalGap"
          label="認証"
          component={Checkbox}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.checked);
          }}
          disabled={exhibit}
        />
        {formProps.errors['global_gap'] !== undefined ? (
          <a className="form__error">{formProps.errors['global_gap']}</a>
        ) : null}
      </div>
    </>
  );
};
