import {FormikConfig} from 'formik';

export const main: Pick<FormikConfig<any>, 'validate'>['validate'] = (
  values: any
) => {
  const errors: any = {};

  if (!values.mail) {
    errors.mail = 'メールアドレスを入力してください';
  }
  if (!values.password) {
    errors.password = 'パスワードを入力してください';
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = 'パスワード（確認用）を入力してください';
  }

  return errors;
};
