import {FormikConfig} from 'formik';

export const chat: Pick<FormikConfig<any>, 'validate'>['validate'] = (
  values: any
) => {
  const errors: any = {};

  if (!values.message) {
    errors.message = 'コメントを入力してください';
  }

  return errors;
};
