import {useEffect, useState, useCallback} from 'react';
import {unwrapResult} from '@reduxjs/toolkit';
import _ from 'lodash';

import {RootState} from '@/app/rootReducer';

import {AppDispatch} from '@/app/store';

import {useDispatch, useSelector} from 'react-redux';

import {
  fetchShippingCosts,
  updateCurrentData,
  resetCurrentData,
  updateShippingCosts,
  updateError,
  updateCurrentRelayData,
} from '@/redux/shippingCostsSlice';
import utils from '@/lib/utils';

export const useShippingTable = () => {
  const dispatch: AppDispatch = useDispatch();
  const me = useSelector((state: RootState) => state.me);
  const {sizeCosts, relayCosts, loading, error} = useSelector(
    (state: RootState) => state.shippingCosts
  );

  useEffect(() => {
    const getInitialData = async () => {
      try {
        const result = await dispatch(fetchShippingCosts(me.id));

        unwrapResult(result);
      } catch (err) {
        utils.errors.handleBasics(err);
      }
    };

    getInitialData();
  }, [dispatch, me]);

  // バリデーションチェック
  useEffect(() => {
    let error: string | null = null;

    sizeCosts.forEach((column) => {
      _.map(column, (value) => {
        if (value === '' || !utils.string.isInteger(value)) {
          error = '整数を入力して下さい';
        }
      });
    });

    relayCosts.forEach((column) => {
      _.map(column, (value) => {
        if (
          value.relayCost === '' ||
          !utils.string.isInteger(value.relayCost)
        ) {
          error = '整数を入力して下さい';
        }
      });
    });

    dispatch(updateError({error}));
  }, [dispatch, sizeCosts, relayCosts]);

  const updateMyData = useCallback(
    (rowIndex: number, columnId: string, value: string) => {
      dispatch(updateCurrentData({rowIndex, columnId, value}));
    },
    [dispatch]
  );

  const updateMyRelayData = useCallback(
    (rowIndex: number, columnId: string, value: string) => {
      dispatch(updateCurrentRelayData({rowIndex, columnId, value}));
    },
    [dispatch]
  );

  return {
    loading,
    sizeCosts,
    updateMyData,
    relayCosts,
    updateMyRelayData,
    error,
  };
};

export const useResetModal = () => {
  const [isResetModal, setIsResetModal] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();

  const resetData = useCallback(() => {
    dispatch(resetCurrentData());
    setIsResetModal(false);
    utils.notifications.show('success', 'データをリセットしました。');
  }, [dispatch]);

  return {isResetModal, setIsResetModal, resetData};
};

export const useUpdateModal = () => {
  const [isUpdateModal, setIsUpdateModal] = useState<boolean>(false);
  const me = useSelector((state: RootState) => state.me);
  const {sizeCosts, relayCosts} = useSelector(
    (state: RootState) => state.shippingCosts
  );

  const dispatch: AppDispatch = useDispatch();

  const updateData = useCallback(async () => {
    try {
      setIsUpdateModal(false);
      const result = await dispatch(
        updateShippingCosts({userId: me.id, sizeCosts, relayCosts})
      );

      unwrapResult(result);
      utils.notifications.show('success', 'データを保存しました。');
    } catch (err) {
      utils.errors.handleBasics(err);
    }
  }, [me, sizeCosts, relayCosts, dispatch]);

  return {isUpdateModal, setIsUpdateModal, updateData};
};
