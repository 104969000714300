import React from 'react';
import BaseModal from '@/components/Modal/_Base';
import Rating from 'react-rating';

import Label from './Label';
import {Icon, TextArea, Button, Checkbox} from 'semantic-ui-react';
import {Formik, Field, FormikProps, FormikValues} from 'formik';
import {submit} from './handlers';
import {useSalesTable, CLOSED_SORT_COND, DEFAULT_SORT_COND} from '../hooks';

import './index.scss';

interface Props {
  sourceUserId: number;
  ratingTargets: Array<{
    sale_id: number;
    dist_user_id: number;
  }>;
  ratingModal: boolean;
  setRatingModal: React.Dispatch<React.SetStateAction<boolean>>;
  parentFormProps: any;
  isBid?: boolean;
}

const RatingModal: React.FC<Props> = ({
  sourceUserId,
  ratingModal,
  setRatingModal,
  ratingTargets,
  parentFormProps,
  isBid,
}) => {
  const sort = isBid ? CLOSED_SORT_COND : DEFAULT_SORT_COND;
  const {currentPagination} = useSalesTable({sort, isBid});

  return (
    <BaseModal
      title="取引はいかがでしたか？"
      isModal={ratingModal}
      handleCloseModal={setRatingModal}
      size="md"
    >
      <div className="a__rating-modal">
        <Formik
          initialValues={{
            point: 0,
            comment: '',
            checkbox: false,
          }}
          onSubmit={async (value, formikProps) => {
            await submit(
              value,
              ratingTargets,
              sourceUserId,
              formikProps,
              setRatingModal,
              parentFormProps
            );
            await currentPagination({sort});
          }}
        >
          {(formProps: FormikProps<FormikValues>) => (
            <div className="a__rating-modal__body">
              <div className="b__field__row">
                <Rating
                  initialRating={formProps.values['point']}
                  emptySymbol={<Icon name="star" color="grey" />}
                  fullSymbol={<Icon name="star" color="yellow" />}
                  onChange={(value: any) => {
                    formProps.setFieldValue('point', value);
                  }}
                />
                <Label option="required" />
              </div>

              <div className="b__field">
                <Label text="コメント" option="optional" />

                <Field
                  name="comment"
                  component={TextArea}
                  maxLength={200}
                  onChange={(_: any, textarea: any) => {
                    formProps.setFieldValue('comment', textarea.value);
                  }}
                />
                <div className="caution">(最大200文字)</div>
              </div>

              <div className="b__field">
                <Field
                  name="checkbox"
                  label="同一コメント・点数でレビューしてよろしいですか"
                  component={Checkbox}
                  onChange={(_: any, checkbox: any) => {
                    formProps.setFieldValue('checkbox', checkbox.checked);
                  }}
                />
              </div>

              <div className="b__field">
                同じ競りを再度レビューした場合は、上書きでの更新となります。
              </div>

              <Button
                fluid
                positive
                disabled={formProps.isSubmitting}
                loading={formProps.isSubmitting}
                onClick={() => formProps.handleSubmit()}
              >
                まとめてレビューする
              </Button>
            </div>
          )}
        </Formik>
      </div>
    </BaseModal>
  );
};

export default RatingModal;
