import {mergeDate} from '@/lib/utils/date';
import paths from '@/config/paths';
import {createStocks} from '@/api/stocks';
import notify from '@/migration/notification';
import {CreateParams} from '@/modules/stock/utils/set-param';

// TODO 型づけ
export const submit = async (
  value: any,
  actions: any,
  history: any,
  setModal: any
) => {
  try {
    actions.setSubmitting(true);

    const {stockParams} = CreateParams(value);

    await createStocks(stockParams);

    notify('自動再出品登録しました。', 'success');
    history.push(paths.stocks.main);
  } catch (error: any) {
    console.log(error);
    // notify(error.message, 'error');
  } finally {
    actions.setSubmitting(false);
    setModal(false);
  }
};
