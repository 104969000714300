import React from 'react';
import {Loader} from 'semantic-ui-react';

import {Props} from './types';

const Pagination: React.FC<Props> = (props) => {
  return (
    <Loader className={props.className} size="small" active={props.active} />
  );
};

export default Pagination;
