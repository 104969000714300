import React from 'react';
import classNames from 'classnames';

import {Props} from './types';

import './index.scss';

const Label: React.FC<Props> = (props) => {
  return (
    <div
      className={classNames('b__form-label', [
        props.className,
        {disabled: props.disabled},
      ])}
    >
      {props.text}
      {props.required && <span className="b__required-label">必須</span>}
      {props.optional && <span className="b__optional-label">任意</span>}
    </div>
  );
};
export default Label;
