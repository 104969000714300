import {mergeDate} from '@/lib/utils/date';
import {StockParams} from '../type';

const toInt = (str: string) => {
  return str ? parseInt(str, 10) : 0;
};

export const CreateParams = (values: any) => {
  let fishingDay = '';

  if (
    values.fishingDate.year &&
    values.fishingDate.month &&
    values.fishingDate.day
  ) {
    fishingDay = mergeDate(
      values.fishingDate.year,
      values.fishingDate.month,
      values.fishingDate.day,
      'YYYY-MM-DD'
    );
  }

  const soilInfo = values.soilAdditionalInfo
    ? values.soilAdditionalInfo
        .map((e: any, i: number) => (e ? i.toString() : undefined))
        .filter((f: undefined | string) => f !== undefined)
        .join(',')
    : null;

  const pesticidesInfo = values.pesticidesAdditionalInfo
    ? values.pesticidesAdditionalInfo
        .map((e: any, i: number) => {
          if (values.pesticides === null) return undefined;
          switch (values.pesticides) {
            case 2:
              if (i === 0) {
                return e.toString();
              } else {
                return undefined;
              }
            case 3:
              if (i !== 0 && e) return i.toString();
              else {
                return undefined;
              }
            default:
              return undefined;
          }
        })
        .filter((f: undefined | string) => f !== undefined)
        .join(',')
    : null;

  const stockParams: StockParams = {
    area: values.area,
    capacity: toInt(values.capacity),
    cleaningMethod: values.cleaningMethod,
    closeBidMin: values.closeBidMin * 60,
    count: 0,
    deliveryMethod: values.deliveryMethod,
    explanation: values.explanation,
    fishingDay,
    imageJson: values.imageJson,
    isDummy: values.isDummy,
    isLandAfterFix: values.isLandAfterFix,
    repeatLimitDate: mergeDate(
      values.repeatLimitDate.year,
      values.repeatLimitDate.month,
      values.repeatLimitDate.day,
      'YYYY-MM-DD'
    ),
    // savingMethod: values.savingMethod,
    sellerUserId: values.sellerUserId,
    shippingExpense: values.shippingExpense,
    shippingSize: values.shippingSize,
    shippingTiming: values.shippingTiming,
    size: values.size,
    startPrice: toInt(values.startPrice),
    weightAdditionalInfo: values.weightAdditionalInfo,

    // 追加
    name: values.name,
    breed: values.breed,
    category: values.category,
    grade: values.grade,
    rank: values.rank,
    isUnevenness: values.isUnevenness,
    producer: values.producer,
    origin: values.origin,
    originAdditionalInfo: values.originAdditionalInfo,
    harvestTiming: values.harvestTiming,
    harvestDate: fishingDay,
    saveMethodUser: values.saveMethodUser,
    saveMethodSeller: values.saveMethodSeller,
    saveMethodUserAdditionalInfo: values.saveMethodUserAdditionalInfo,
    cultivationMethod: values.cultivationMethod,
    soil: values.soil,
    soilAdditionalInfo: soilInfo,
    pesticides: values.pesticides,
    pesticidesAdditionalInfo: pesticidesInfo,
    genetic: values.genetic,
    jas: values.jas,
    individualObligations: values.individualObligations,
    globalGap: values.globalGap ? '認証' : null,
  };

  if (values.weight) stockParams.weight = toInt(values.weight);

  if (values.fixPrice) stockParams.fixPrice = toInt(values.fixPrice);

  if (values.shippingTiming === 3)
    stockParams.shippingExpense = values.shippingTiming;

  return {stockParams};
};
