import React from 'react';
import classNames from 'classnames';
import {Input, Button} from 'semantic-ui-react';

import * as handlers from './handlers';
import {SearchProps} from './types';

import './index.scss';

const Search: React.FC<SearchProps> = (props) => {
  const {field, form, className, ...restProps} = props;

  return (
    <div className={classNames('a__form-search', className)}>
      <div className="bf__input">
        <div className="b__search-input">
          <Input
            className={classNames({
              outline: restProps.outline,
            })}
            error={!!form.errors[field.name]}
            autoComplete={restProps.autocomplete || 'off'}
            style={restProps.inputStyle}
            type={restProps.type || 'text'}
            placeholder={restProps.placeholder}
            maxLength={restProps.maxLength || 100}
            disabled={restProps.disabled}
            onKeyPress={handlers.inputKeyPress(props)}
            {...field}
          />
        </div>
        <span className="b__button">
          <Button
            positive
            fluid
            type="button"
            onClick={handlers.buttonClick(props)}
          >
            検索
          </Button>
        </span>
      </div>
    </div>
  );
};

export default Search;
