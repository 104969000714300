import React from 'react';
import classNames from 'classnames';
import {TextArea as SemanticUiTextArea} from 'semantic-ui-react';

import {TextAreaProps} from './types';

import './index.scss';

const TextArea: React.FC<TextAreaProps> = (props) => {
  const {field, outline, disabled, fluid, className, dark, ...restProps} =
    props;

  return (
    <SemanticUiTextArea
      className={classNames(
        'a__form-text-area',
        {
          fluid,
          outline,
          disabled,
          dark,
        },
        className
      )}
      disabled={disabled}
      {...field}
      {...restProps}
      maxLength={1000}
    />
  );
};

export default TextArea;
