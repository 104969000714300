import * as users from './users';
import * as csv from './csv';
import * as sales from './sales';
import * as shippingCosts from './shippingCosts';
import * as shippingRelayCosts from './shippingRelayCosts';

// TODO stocksでまとめる
// import * as stocksPaging from './stocksPaging';
import * as stocksStockId from './stocksStockId';
import * as stocksAutoStockId from './stocksAutoStockId';

import * as stocks from './stocks';

export default {
  users,
  csv,
  sales,
  // stocksPaging,
  stocksStockId,
  stocksAutoStockId,
  stocks,
  shippingCosts,
  shippingRelayCosts,
};
