import React, {FC} from 'react';
import {Input, InputProps} from 'semantic-ui-react';
import './index.scss';

export type Block2Props = Pick<InputProps, 'label' | 'labelPosition' | 'type'> &
  Partial<
    Pick<InputProps, 'name' | 'onBlur' | 'onChange' | 'placeholder' | 'value'>
  >;

const Block2: FC<Block2Props> = ({
  label,
  labelPosition,
  name,
  onBlur,
  onChange,
  placeholder,
  type,
  value,
}) => (
  <Input
    className="c__input"
    label={label}
    labelPosition={labelPosition}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    placeholder={placeholder}
    type={type}
    value={value}
  />
);

export default Block2;
