import React from 'react';
import classNames from 'classnames';
import {ErrorMessage} from 'formik';

import Error from './../../Error';
import Base from './../_Base';
import {Props} from './types';

import './index.scss';

const BlockInput: React.FC<Props> = (props) => {
  return (
    <div
      className={classNames({
        fluid: props.fluid,
        [props.containerClass ? props.containerClass : '']: true,
      })}
    >
      <Base
        className={classNames({
          [props.className ? props.className : '']: true,
          'block-input': true,
          dark: props.dark,
        })}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        disabled={props.disabled}
        field={props.field}
        form={props.form}
        fluid={props.fluid}
        search={props.search}
        type={props.type}
        style={props.style}
      />
      <ErrorMessage name={props.field.name} component={Error} />
    </div>
  );
};

export default BlockInput;
