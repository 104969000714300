import React from 'react';

import {Input, InputProps} from 'semantic-ui-react';

import './index.scss';

const Default: React.FC<InputProps> = (props) => {
  const {field, disabled, ...rest} = props;

  return (
    <div className="a__input__default">
      <Input disabled={disabled} {...field} {...rest} />
    </div>
  );
};

export default Default;
