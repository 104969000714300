import React from 'react';

import _Base from './../_Base';

import {Button} from '@/components';
import {Props} from './types';

import './index.scss';

const Normal: React.FC<Props> = (props) => {
  return (
    <_Base
      title={props.title}
      isModal={props.isModal}
      handleCloseModal={props.handleCloseModal}
      size={props.size}
    >
      <div className="w__common-modal">
        <div className="w__modal-text">{props.children}</div>
        <div
          className="w__modal-buttons"
          style={{justifyContent: props.submitText ? undefined : 'center'}}
        >
          <Button.Block
            disabled={props.disabled}
            onClick={() => props.handleCloseModal(!props.isModal)}
            style2={props.submitText ? undefined : {marginRight: 0}}
          >
            キャンセル
          </Button.Block>
          {props.submitText ? (
            <Button.Block
              onClick={props.onClick}
              loading={props.loading}
              disabled={props.disabled}
              color={props.submitColor}
            >
              {props.submitText}
            </Button.Block>
          ) : null}
        </div>
      </div>
    </_Base>
  );
};
export default Normal;
