import React from 'react';
import RcPagination from 'rc-pagination';
import classNames from 'classnames';

import {Props} from './types';

import 'rc-pagination/assets/index.css';
import './index.scss';

const Pagination: React.FC<Props> = (props) => {
  return (
    <div className="gc__pagination">
      <RcPagination
        style={{
          display: props.totalCount ? 'block' : 'none',
        }}
        className={classNames({
          disabled: props.disabled,
        })}
        total={props.totalCount}
        current={props.current}
        showLessItems
        pageSize={props.pageSize ? props.pageSize : 10}
        onChange={(current: number) => {
          props.updateCurrent(current);
        }}
        // locale="ja_JP"
        showSizeChanger={false}
        onShowSizeChange={props.changePageSize}
      />
    </div>
  );
};

export default Pagination;
