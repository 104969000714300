import axios from 'axios';
import * as env from './env';
import storage from 'store';

export const signInWitSNS = (provider: 'Facebook' | 'Google') => {
  const domain = env.get().COGNITO_DOMAIN;
  const clientId = env.get().COGNITO_CLIENT_ID;
  const redirectURI = env.get().COGNITO_REDIRECT_URI;

  window.location.assign(
    `${domain}/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectURI}&identity_provider=${provider}`
  );
};

export const setToken = async (code: string) => {
  const domain = env.get().COGNITO_DOMAIN;
  const clientId = env.get().COGNITO_CLIENT_ID;
  const redirectURI = env.get().COGNITO_REDIRECT_URI;

  storage.clearAll();

  const localStorageMainKey: string =
    'CognitoIdentityServiceProvider.' + clientId;

  const params: URLSearchParams = new URLSearchParams();
  params.append('grant_type', 'authorization_code');
  params.append('redirect_uri', redirectURI);
  params.append('code', code);
  params.append('client_id', clientId);

  // Tokenの取得
  const token = await axios.post(domain + '/oauth2/token', params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  const bearer: string = 'Bearer ' + token.data.access_token;

  // ユーザー情報の取得
  const userInfo = await axios.post(
    domain + '/oauth2/userInfo',
    {},
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: bearer,
      },
    }
  );

  const localStorageSubKey: string =
    localStorageMainKey + '.' + userInfo.data.sub;
  localStorage.setItem(
    localStorageSubKey + '.accessToken',
    token.data.access_token
  );
  localStorage.setItem(localStorageSubKey + '.idToken', token.data.id_token);
  localStorage.setItem(
    localStorageSubKey + '.refreshToken',
    token.data.refresh_token
  );
  localStorage.setItem(localStorageSubKey + '.clockDrift', '0');
  localStorage.setItem(
    localStorageMainKey + '.LastAuthUser',
    userInfo.data.sub
  );
};
