import React from 'react';
import {Icon} from 'semantic-ui-react';
import classNames from 'classnames';

import {Props} from './types';

import './index.scss';

const Sort: React.FC<Props> = (props) => {
  const isActive = (orderBy: string) => {
    if (`${orderBy}${props.accessor}` === props.currentSort) {
      return true;
    }
    return false;
  };

  return (
    <span
      className={classNames('a__table-sort c__th', {disabled: props.disabled})}
      onClick={() => {
        // loading中はソートさせない
        if (props.disabled) {
          return;
        }

        const currentSortOrderBy = props.currentSort.slice(0, 1);
        const currentSortAccessor = props.currentSort.slice(1);
        let current = 1;

        let nextOrderBy = '+';
        if (currentSortOrderBy === '+') {
          nextOrderBy = '-';
        }

        if (props.accessor !== currentSortAccessor) {
          nextOrderBy = '+';
        }

        props.updateCurrent(`${nextOrderBy}${props.accessor}`, current);
      }}
    >
      {props.value}
      <span className="sort">
        <Icon name="sort up" className={classNames({active: isActive('+')})} />
        <Icon
          name="sort down"
          className={classNames({active: isActive('-')})}
        />
      </span>
    </span>
  );
};

export default Sort;
