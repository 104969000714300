import React from 'react';
import {Icon} from 'semantic-ui-react';

import Base from './../_Base';
import {Props, Pattern} from './types';

const patternColor: {[key in Pattern]: string} = {
  facebook: '#3B5998',
  google: '#DB4437',
};

const SNS: React.FC<Props> = ({
  loading,
  disabled,
  type,
  fluid,
  color,
  onClick,
  pattern,
  children,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Base
        loading={loading}
        disabled={disabled}
        type={type}
        fluid={fluid}
        color={color}
        onClick={onClick}
        style={{
          backgroundColor: patternColor[pattern],
        }}
      >
        <div
          style={{
            color: 'white',
            fontSize: 14,
            position: 'relative',
            bottom: 2,
          }}
        >
          <Icon name={pattern} inverted />
          {children}
        </div>
      </Base>
    </div>
  );
};

export default SNS;
