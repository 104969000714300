import env from './../../config/env/index';

export const get = () => {
  const envcode: string = process.env.REACT_APP_ENVCODE || 'local';

  switch (envcode) {
    case 'local': {
      return {...env.local};
    }
    case 'dev': {
      return {...env.dev};
    }
    case 'stg': {
      return {...env.stg};
    }
    case 'prd': {
      return {...env.prd};
    }
    default: {
      return {...env.local};
    }
  }
};
