import {useState, useEffect} from 'react';
import utils from '@/lib/utils';
import {useDispatch} from 'react-redux';
import {fetchMe} from '@/redux/meSlice';
import {AppDispatch} from '@/app/store';

export const useAppState = () => {
  const dispatch: AppDispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isAuthed, setIsAuthed] = useState(false);

  useEffect(() => {
    async function fetchAppState() {
      try {
        await utils.amplify.getUser();
        await dispatch(fetchMe());
        setIsAuthed(true);
      } catch {
        // 何もしない
      } finally {
        setIsLoading(false);
      }
    }

    fetchAppState();
  }, [dispatch]);

  return {isAuthed, isLoading};
};
