import React from 'react';
import {Dropdown, DropdownProps} from 'semantic-ui-react';

import './index.scss';

const SemanticDropdown: React.FC<DropdownProps> = (props) => {
  const {field, disabled, ...rest} = props;

  return (
    <div className="a__dropdown">
      <Dropdown disabled={disabled} {...field} {...rest} />
    </div>
  );
};

export default SemanticDropdown;
