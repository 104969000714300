import React from 'react';
import {TextArea, TextAreaProps} from 'semantic-ui-react';

import './index.scss';

const SemanticTextArea: React.FC<TextAreaProps> = (props) => {
  const {field, ...rest} = props;

  return (
    <div className="a__textarea">
      <TextArea {...field} {...rest} />
    </div>
  );
};

export default SemanticTextArea;
