import React from 'react';
import {Field, FormikProps, FormikValues, ErrorMessage} from 'formik';

import Dropzone from '../Dropzone';

import {Input, Label, Dropdown, SemanticTextArea} from '@/components/Form';

// OPTIONS
import {
  shippingExpenses,
  deliveryMethods,
  shippingTimings,
  shippingSizes,
  areas,
  closeBidMins,
  featureYears,
  months,
  days,
} from '@/config/constants/options';

import './index.scss';
import {StockInputs} from '@/modules/stock/public';

interface Props {
  formProps: FormikProps<FormikValues>;
  exhibit?: boolean;
  isCompany: boolean;
  shippingProvider: number;
  cost: {id: number; userId: number};
}

const Form: React.FC<Props> = ({
  exhibit,
  formProps,
  cost,
  isCompany,
  shippingProvider,
}) => {
  const {values} = formProps;

  return (
    <form className="b__stocks-detail__form-main">
      <Dropzone name="imageJson" disabled={exhibit} />
      <div className="b__field">
        <Label text="商品名 (最大50文字)" required />
        <Field
          name="name"
          component={Input.Default}
          maxLength="50"
          placeholder=""
          disabled={exhibit}
        />
        {formProps.errors['name'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="name" />
          </a>
        ) : null}
      </div>

      {/* 追加 */}
      <StockInputs formProps={formProps} exhibit={exhibit} />
      {/*       
      <div className="b__field">
        <Label text="品種名 (最大50文字)" required />
        <Field
          name="breed"
          component={Input.Default}
          maxLength="50"
          placeholder="例）りんご"
          disabled={exhibit}
        />
        {formProps.errors['breed'] !== undefined ? (
          <a className="form__error">{<ErrorMessage name="breed" />}</a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="サイズ（JA規格）" optional />
        <Field
          name="size"
          component={Dropdown}
          fluid
          options={[
            {
              key: '0',
              value: null,
              name: '',
              text: '未設定',
            },
            ...sizes,
          ]}
          selection
          placeholder="選択してください"
          disabled={exhibit}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['size'] !== undefined ? (
          <a className="form__error">{<ErrorMessage name="size" />}</a>
        ) : null}
      </div>

      <div className="b__field__m0">
        <Field
          name="isUnevenness"
          label="不揃い"
          component={Checkbox}
          disabled={exhibit}
          checked={formProps.values.isUnevenness}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.checked);
          }}
        />
      </div>

      <div className="b__field">
        <Label text="重量 (最大10,000,000g)" optional />
        <Field
          name="weight"
          component={Input.Default}
          maxLength="8"
          placeholder="例) 1500"
          disabled={exhibit}
          label={{basic: true, content: 'g'}}
          labelPosition="right"
        />
        {formProps.errors['weight'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="weight" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Field
          name="weightAdditionalInfo"
          component={Input.Default}
          maxLength="50"
          placeholder="備考"
          disabled={exhibit}
        />
        <div className="b__paragraph">(最大50文字)</div>
      </div>

      <div className="b__field">
        <Label text="生産者 (最大50文字)" optional />
        <Field
          name="producer"
          component={Input.Default}
          maxLength="50"
          placeholder="山田さん"
          disabled={exhibit}
        />
        {formProps.errors['producer'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="producer" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="等級" optional />
        <Field
          name="grade"
          component={Dropdown}
          fluid
          options={[
            {
              key: '0',
              value: null,
              name: '',
              text: '未設定',
            },
            ...grades,
          ]}
          selection
          placeholder="選択してください"
          disabled={exhibit}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['grade'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="grade" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="産地" optional />
        <Field
          name="origin"
          component={Dropdown}
          fluid
          options={fishingSpots}
          selection
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
          placeholder="選択してください"
          disabled={exhibit}
        />
      </div>

      <div className="b__field">
        <Field
          name="originAdditionalInfo"
          component={Input.Default}
          maxLength="50"
          placeholder="詳細ポイント"
          disabled={exhibit}
        />
      </div>

      <div className="b__field">
        <Label text="平均糖度" optional />
        <Field
          name="averageSugarContent"
          component={Input.Default}
          maxLength="5"
          placeholder="例）10.0"
          disabled={exhibit}
          label={{basic: true, content: '%'}}
          labelPosition="right"
        />
        {formProps.errors['average_sugar_content'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="average_sugar_content" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="収穫日" optional />
        <div className="b__field__row">
          <div className="b__field__column">
            <Field
              name="fishingDate.year"
              component={Dropdown}
              fluid
              selection
              placeholder={now('YYYY')}
              disabled={exhibit}
              options={years}
              onChange={(_: any, field: any) => {
                formProps.setFieldValue(field.name, field.value);
              }}
            />
            <span>年</span>
          </div>
          <div className="b__field__column">
            <Field
              name="fishingDate.month"
              component={Dropdown}
              fluid
              selection
              placeholder={now('M')}
              disabled={exhibit}
              options={months}
              onChange={(_: any, field: any) => {
                formProps.setFieldValue(field.name, field.value);
              }}
            />
            <span>月</span>
          </div>
          <div className="b__field__column">
            <Field
              name="fishingDate.day"
              component={Dropdown}
              fluid
              selection
              placeholder={now('D')}
              disabled={exhibit}
              options={days}
              onChange={(_: any, field: any) => {
                formProps.setFieldValue(field.name, field.value);
              }}
            />
            <span>日</span>
          </div>
        </div>
      </div>

      <div className="b__field__m0">
        <Field
          name="isLandAfterFix"
          label="落札後収穫"
          component={Checkbox}
          disabled={exhibit}
          checked={formProps.values.isLandAfterFix}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.checked);
          }}
        />
      </div>

      <div className="b__field">
        <Label text="収穫タイミング" optional />
        <Field
          name="harvestTiming"
          component={Dropdown}
          fluid
          options={[
            {
              key: '0',
              value: null,
              name: '',
              text: '未設定',
            },
            ...harvestTiming,
          ]}
          selection
          placeholder="選択してください"
          disabled={exhibit}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['harvestTiming'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="harvestTiming" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="出品者側保管方法" required />
        <Field
          name="saveMethodSeller"
          component={Dropdown}
          fluid
          options={saveMethod}
          selection
          placeholder="選択してください"
          disabled={exhibit}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['saveMethodSeller'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="saveMethodSeller" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="ご家庭での推奨保管方法" optional />
        <Field
          name="saveMethodUser"
          component={Dropdown}
          fluid
          options={[
            {
              key: '0',
              value: null,
              name: '',
              text: '未設定',
            },
            ...saveMethod,
          ]}
          selection
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
          placeholder="選択してください"
          disabled={exhibit}
        />
      </div>

      <div className="b__field">
        <Field
          name="saveMethodUserAdditionalInfo"
          component={Input.Default}
          maxLength="50"
          placeholder="詳細ポイント）明石海峡・加太 など"
          disabled={exhibit}
        />
      </div>

      <div className="b__field">
        <Label text="栽培方法" optional />
        <Field
          name="cultivationMethod"
          component={Dropdown}
          fluid
          options={[
            {
              key: '0',
              value: null,
              name: '',
              text: '未設定',
            },
            ...cultivationMethod,
          ]}
          selection
          placeholder="選択してください"
          disabled={exhibit}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['cultivationMethod'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="cultivationMethod" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="土壌" optional />
        <Field
          name="soil"
          component={Dropdown}
          fluid
          options={[
            {
              key: '0',
              value: null,
              name: '',
              text: '未設定',
            },
            ...soil,
          ]}
          selection
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
          placeholder="選択してください"
          disabled={exhibit}
        />

        {values.soil === 4 && (
          <div className="stack">
            {[...soilAdditionalInfo].map((e, i) => {
              return (
                <Field
                  key={e.key}
                  name={`soilAdditionalInfo[${i}]`}
                  label={e.text}
                  component={Checkbox}
                  onChange={(_: any, field: any) => {
                    formProps.setFieldValue(field.name, field.checked);
                  }}
                  disabled={exhibit}
                  checked={values.soilAdditionalInfo[i]}
                />
              );
            })}
          </div>
        )}
      </div>
      <div className="b__field">
        <Label text="農薬" optional />
        <Field
          name="pesticides"
          component={Dropdown}
          fluid
          options={[
            {
              key: '0',
              value: null,
              name: '',
              text: '未設定',
            },
            ...pesticides,
          ]}
          selection
          placeholder="選択してください"
          disabled={exhibit}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['pesticides'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="pesticides" />
          </a>
        ) : null}
        {values.pesticides === 2 && (
          <Field
            name="pesticidesAdditionalInfoText"
            component={Input.Default}
            maxLength="50"
            placeholder="節減対象農薬を節減「当地比〇割減、〇〇地域比○割減）」"
            disabled={exhibit}
          />
        )}
        {values.pesticides === 3 && (
          <div className="stack">
            {pesticidesAdditionalInfo.map((e, i) => {
              return (
                <Field
                  key={e.key}
                  name={`pesticidesAdditionalInfoCheck[${i}]`}
                  label={e.text}
                  component={Checkbox}
                  onChange={(_: any, field: any) => {
                    formProps.setFieldValue(field.name, field.checked);
                  }}
                  disabled={exhibit}
                  checked={values.pesticidesAdditionalInfoCheck[i]}
                />
              );
            })}
          </div>
        )}
      </div>

      <div className="b__field">
        <Label text="遺伝子組み換え" optional />
        <Field
          name="genetic"
          component={Dropdown}
          fluid
          options={[
            {
              key: '0',
              value: null,
              name: '',
              text: '未設定',
            },
            ...genetic,
          ]}
          selection
          placeholder="選択してください"
          disabled={exhibit}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['genetic'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="genetic" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="有機JAS認定" optional />
        <Field
          name="jas"
          component={Dropdown}
          fluid
          options={[
            {
              key: '0',
              value: null,
              name: '',
              text: '未設定',
            },
            ...jas,
          ]}
          selection
          placeholder="選択してください"
          disabled={exhibit}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['jas'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="jas" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="個別的義務表示事項 (最大50文字)" optional />
        <Field
          name="individualObligations"
          component={Input.Default}
          maxLength="50"
          placeholder="山田さん"
          disabled={exhibit}
        />
        {formProps.errors['individualObligations'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="individualObligations" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="グローバルGAP「認証」 (最大50文字)" optional />
        <Field
          name="globalGap"
          component={Input.Default}
          maxLength="50"
          placeholder="山田さん"
          disabled={exhibit}
        />
        {formProps.errors['globalGap'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="globalGap" />
          </a>
        ) : null}
      </div> */}

      {/* ここまで追加 */}

      <div className="b__field">
        <Label text="配送料の負担" required />
        <Field
          name="shippingExpense"
          component={Dropdown}
          fluid
          selection
          disabled={exhibit}
          placeholder="選択してください"
          options={shippingExpenses.filter(
            ({value}) =>
              value !== 3 ||
              (isCompany &&
                shippingProvider !== 0 &&
                cost.id !== 0 &&
                cost.userId !== 0)
          )}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['shippingExpense'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="shippingExpense" />
          </a>
        ) : null}
      </div>

      {formProps.values.shippingExpense === 3 ? (
        <div className="b__field">
          <Label text="配送サイズ区分" required />
          <Field
            name="shippingSize"
            component={Dropdown}
            disabled={exhibit}
            fluid
            placeholder="選択してください"
            selection
            options={shippingSizes}
            onChange={(_: any, field: any) => {
              formProps.setFieldValue(field.name, field.value);
            }}
          />
          {formProps.errors['shippingSize'] !== undefined ? (
            <a className="form__error">
              <ErrorMessage name="shippingSize" />
            </a>
          ) : null}
        </div>
      ) : null}

      <div className="b__field">
        <Label text="配送方法" required />
        <Field
          name="deliveryMethod"
          component={Dropdown}
          fluid
          selection
          disabled={exhibit}
          placeholder="選択してください"
          options={deliveryMethods}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['deliveryMethod'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="deliveryMethod" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="発送までの日数" required />
        <Field
          name="shippingTiming"
          component={Dropdown}
          fluid
          selection
          disabled={exhibit}
          placeholder="選択してください"
          options={shippingTimings}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['shippingTiming'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="shippingTiming" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="発送元の地域" required />
        <Field
          name="area"
          component={Dropdown}
          fluid
          selection
          disabled={exhibit}
          placeholder="選択してください"
          options={areas}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['area'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="area" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="出品時の開始価格" required />
        <Field
          name="startPrice"
          component={Input.Default}
          maxLength="8"
          disabled={exhibit}
          placeholder="入力してください"
          label={{basic: true, content: '¥'}}
          labelPosition="left"
        />
        {formProps.errors['startPrice'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="startPrice" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="即決価格" optional />
        <Field
          name="fixPrice"
          component={Input.Default}
          maxLength="8"
          disabled={exhibit}
          placeholder="入力してください"
          label={{basic: true, content: '¥'}}
          labelPosition="left"
        />
        {formProps.errors['fixPrice'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="fixPrice" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="出品期限" required />
        <Field
          name="closeBidMin"
          component={Dropdown}
          fluid
          selection
          disabled={exhibit}
          placeholder="選択してください"
          options={closeBidMins}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['closeBidMin'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="closeBidMin" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="出品商品説明" required />
        <Field
          name="explanation"
          component={SemanticTextArea}
          rows="5"
          disabled={exhibit}
          maxLength="1000"
        />
        <div>(最大1,000文字)</div>
        {formProps.errors['explanation'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="explanation" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text={`繰り返し回数（出品した回数 ${values.count}）`} />
        <Field
          name="capacity"
          component={Input.Default}
          maxLength="10"
          disabled={exhibit}
          placeholder="入力してください"
          label={{basic: true, content: '回'}}
          labelPosition="right"
        />
        {formProps.errors['capacity'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="capacity" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="繰り返し期限" />
        <div className="b__field__row">
          <div className="b__field__column">
            <Field
              name="repeatLimitDate.year"
              component={Dropdown}
              fluid
              selection
              disabled={exhibit}
              options={featureYears}
              onChange={(_: any, field: any) => {
                formProps.setFieldValue(field.name, field.value);
              }}
            />
            <span>年</span>
          </div>
          <div className="b__field__column">
            <Field
              name="repeatLimitDate.month"
              component={Dropdown}
              fluid
              selection
              disabled={exhibit}
              options={months}
              onChange={(_: any, field: any) => {
                formProps.setFieldValue(field.name, field.value);
              }}
            />
            <span>月</span>
          </div>
          <div className="b__field__column">
            <Field
              name="repeatLimitDate.day"
              component={Dropdown}
              fluid
              selection
              disabled={exhibit}
              options={days}
              onChange={(_: any, field: any) => {
                formProps.setFieldValue(field.name, field.value);
              }}
            />
            <span>日</span>
          </div>
        </div>
        {formProps.errors['repeatLimitDate'] !== undefined ? (
          <a className="form__error">{formProps.errors.repeatLimitDate}</a>
        ) : null}
      </div>
    </form>
  );
};

export default Form;
