import React from 'react';

import _Base from './../_Base';

import {Button} from '@/components';
import {Props} from './types';

import './index.scss';

const Confirm = (props: Props) => {
  return (
    <_Base
      title={props.title}
      isModal={props.isModal}
      handleCloseModal={props.setIsModal}
      size="sm"
    >
      <div className="a__confirm-modal">
        <div className="w__message">{props.message}</div>
        <div className="w__modal-buttons">
          <Button.Block onClick={() => props.setIsModal(!props.isModal)}>
            キャンセル
          </Button.Block>
          <Button.Block
            onClick={props.onSubmit}
            color="main"
            disabled={props.disabled}
          >
            {props.confirmText || 'OK'}
          </Button.Block>
        </div>
      </div>
    </_Base>
  );
};
export default Confirm;
