import React from 'react';
import {useHistory} from 'react-router-dom';
import paths from '@/config/paths';
import {Button} from 'semantic-ui-react';

import {Table, Pagination} from '@/components';

import {useLocalState} from './useLocalState';
import {columns} from './columns';
import {fetchStocksPaging} from '@/redux/stockSlice';
import {useDispatch} from 'react-redux';

import {useDidMount} from './useDidMount';

import './index.scss';

export const StocksMain: React.FC = () => {
  const {loading, setLoading} = useLocalState();
  const history = useHistory();
  const dispatch = useDispatch();
  const {stocks, data} = useDidMount();

  return (
    <div className="a__stocks-main">
      <div className="b-stocks__title">
        <h2>在庫一覧</h2>
        <Button
          onClick={() => {
            history.push(paths.stocks.create);
          }}
        >
          在庫登録を行う
        </Button>
      </div>
      <div className="b-stocks">
        <div className="b-stocks__count">
          件数
          <span>{stocks.pagination.totalCount}</span>件
        </div>
        <Table.Main columns={columns} data={data} loading={loading} />
        <Pagination
          pageSize={10}
          totalCount={Number(stocks.pagination.totalCount)}
          current={stocks.pagination.current}
          updateCurrent={(current: number) => {
            setLoading(true);
            dispatch(
              fetchStocksPaging({limit: 10, page: current, sort: '-id'})
            );
            setLoading(false);
          }}
          disabled={loading}
        />
      </div>
    </div>
  );
};
