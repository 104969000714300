import * as IMAGES from './images';
import * as PAGINATION from './pagination';
import * as STATUS from './status';
import * as OPTIONS from './options';

export default {
  IMAGES,
  PAGINATION,
  STATUS,
  OPTIONS,
};
