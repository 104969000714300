import {
  InputKeyPress,
  ButtonClick,
  _AddSearchWord,
  RemoveSearchWord,
} from './types';

export const inputKeyPress: InputKeyPress =
  ({searchWords, updateSearchWords, field}) =>
  (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    _updateSearchWords({searchWords, updateSearchWords, field});
    event.currentTarget.blur();
  };

export const buttonClick: ButtonClick =
  ({searchWords, updateSearchWords, field}) =>
  () => {
    _updateSearchWords({searchWords, updateSearchWords, field});
  };

export const removeSearchWord: RemoveSearchWord =
  ({removeSearchWord, searchWordIndex}) =>
  () => {
    removeSearchWord(searchWordIndex);
  };

const _updateSearchWords: _AddSearchWord = ({
  searchWords,
  updateSearchWords,
  field,
}) => {
  if (!searchWords.includes(field.value)) {
    updateSearchWords(field.value);
  }
};
