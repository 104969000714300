import React from 'react';
import {Button, Icon} from 'semantic-ui-react';

import {Props} from './types';

import './index.scss';

const GoBack: React.FC<Props> = (props) => {
  return (
    <Button onClick={() => props.history.goBack()} className="b__goback-button">
      <Icon name="arrow left" />
    </Button>
  );
};
export default GoBack;
