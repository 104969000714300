// import React, {useState} from 'react';
import React from 'react';

import {Props} from './types';
import paths from '@/config/paths';
import {Button} from '@/components';

import './index.scss';

export const NotFoundMain: React.FC<Props> = () => {
  return (
    <div className="a__notfound">
      <div className="w__notfound-header">
        <div>
          <div className="b__title-header">ページが見つかりませんでした。</div>
          <div className="b__message-header">
            お探しのページはアドレスが間違っているか、
          </div>
          <div className="b__message-header">削除された可能性があります。</div>
          <div className="b__message-header">{`トップ画面に移動してください。`}</div>
          <div className="w__button">
            <Button.Block
              onClick={() => {
                window.location.assign(paths.signIn.main);
              }}
            >
              {`トップに戻る`}
            </Button.Block>
          </div>
        </div>
      </div>
    </div>
  );
};
