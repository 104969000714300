import paths from '@/config/paths';
import notify from '@/migration/notification';
import {updateStocks} from '@/api/stocks';
import {CreateParams} from '@/modules/stock/public';

export const submit = async (
  stockId: number,
  value: any,
  actions: any,
  history: any,
  setModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    actions.setSubmitting(true);

    const {stockParams} = CreateParams(value);

    await updateStocks(stockId, stockParams);

    notify('在庫の変更内容を保存しました。', 'success');
    history.push(paths.stocks.main);
  } catch (error: any) {
    notify(error.message, 'error');
  } finally {
    actions.setSubmitting(false);
    setModal(false);
  }
};
