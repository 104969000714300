import {isArray, isObject, mapKeys, mapValues, snakeCase} from 'lodash';

const mapKeysDeep = (data: any, callback: any): any => {
  if (isArray(data)) {
    return data.map((innerData) => mapKeysDeep(innerData, callback));
  } else if (isObject(data)) {
    return mapValues(mapKeys(data, callback), (val) =>
      mapKeysDeep(val, callback)
    );
  } else {
    return data;
  }
};

const mapKeysCamelCase = (data: any) =>
  mapKeysDeep(data, (_value: any, key: any) => snakeCase(key));

export default mapKeysCamelCase;
