import React from 'react';

import {Props} from './types';

import './index.scss';

const External: React.FC<Props> = (props) => {
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.value}
    </a>
  );
};

export default External;
