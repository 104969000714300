import axios, {AxiosResponse, AxiosError} from 'axios';
import qs from 'qs';
import * as amplify from './amplify';

import * as env from './env';

export const api = (setting: {auth?: boolean} = {auth: true}) => {
  const myEnv = env.get();

  const axiosInstance = axios.create({
    baseURL: myEnv.API_GO_ENDPOINT,
    timeout: 30000,
  });

  axiosInstance.interceptors.request.use(async (config) => {
    if (config.method === 'get') {
      // クエリパラメータの配列指定に対応
      config.paramsSerializer = (params) => {
        return qs.stringify(params, {arrayFormat: 'repeat'});
      };
    }

    // 認証必須APIのためにAuthorizationヘッダーを付与;
    if (setting.auth) {
      const token = await amplify.getIdToken();
      if (token) {
        Object.assign(config.headers, {
          Authorization: `Bearer ${token}`,
        });
      }
    }

    return config;
  });

  axiosInstance.interceptors.response.use(
    (res: AxiosResponse) => {
      return res;
    },
    (error: AxiosError) => {
      return Promise.reject(error?.response);
    }
  );

  return axiosInstance;
};
