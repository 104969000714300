import React from 'react';

import Base from './../_Base';
import {Props} from './types';

import './index.scss';

const Block: React.FC<Props> = (props) => {
  return (
    <div className="a__button-block" style={props.style2}>
      <Base
        loading={props.loading}
        disabled={props.disabled}
        type={props.type}
        fluid={props.fluid}
        color={props.color}
        onClick={props.onClick}
        style={props.style}
      >
        {props.children}
      </Base>
    </div>
  );
};

export default Block;
