import constants from '@/config/constants';

type Status = {
  title: string;
  value:
    | 'auction'
    | 'unbid'
    | 'done'
    | 'canceled'
    | 'expired'
    | 'banned'
    | 'unknown'
    | 'error';
};

export const getStatus = (
  status: number,
  isBid: boolean,
  isFixed: boolean
): Status => {
  if (status !== constants.STATUS.CANCELED && isBid && !isFixed) {
    return {title: 'オークション中', value: 'auction'};
  }
  if (status === constants.STATUS.DISPLAYING && !isBid) {
    return {title: '未入札', value: 'unbid'};
  }
  if (isFixed) {
    return {title: '落札済み', value: 'done'};
  }
  switch (status) {
    case constants.STATUS.DONE:
      return {title: '落札済み', value: 'done'};
    case constants.STATUS.CANCELED:
      return {title: '取り下げ', value: 'canceled'};
    case constants.STATUS.EXPIRED:
      return {title: '期限切れ', value: 'expired'};
    case constants.STATUS.BANNED:
      return {title: '管理者による出品取り消し', value: 'banned'};
    case constants.STATUS.ERROR:
      return {title: '決済エラー', value: 'error'};
    default:
      return {title: '不明', value: 'unknown'};
  }
};
