import React from 'react';
import {Loader} from 'semantic-ui-react';

import {Props} from './types';

import './index.scss';

const Dimmer: React.FC<Props> = (props) => {
  if (props.active) {
    return (
      <div className="a__dimmer">
        <Loader active />
      </div>
    );
  }

  return null;
};

export default Dimmer;
