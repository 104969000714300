import React from 'react';
import {Button} from 'semantic-ui-react';

import classNames from 'classnames';

import './index.scss';

interface Props {
  active: boolean;
  setCurrentTab: React.Dispatch<React.SetStateAction<boolean>>;
  isRepeat: boolean;
}

const Tab: React.FC<Props> = ({active, setCurrentTab, isRepeat}) => {
  return (
    <div className="b__stocks-detail__tab">
      <li
        className={classNames('b__stocks-detail__list', {
          active,
        })}
      >
        <Button onClick={() => setCurrentTab(true)}>
          {isRepeat ? '自動再出品中' : '自動再出品停止中'}
        </Button>
      </li>
      <li
        className={classNames('b__stocks-detail__list', {
          active: !active,
        })}
      >
        <Button onClick={() => setCurrentTab(false)}>在庫</Button>
      </li>
    </div>
  );
};

export default Tab;
