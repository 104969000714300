import {AxiosError} from 'axios';
import notify from '@/migration/notification';
import _ from 'lodash';

type CustomError = {
  code: string;
  message: string;
  errors: any[];
};

// 共通のエラー表示関数
export const handleBasics = (error: any) => {
  const err = getResponseError(error);

  if (err.message) {
    notify(err.message, 'error');
  } else if (err.errors) {
    _.forEach(err.errors, (err) => {
      notify(err.message, 'error');
    });
  }
};

export const getResponseError = (error: any): CustomError => {
  if (error.response) {
    const axiosError: AxiosError = error;
    return axiosError.response
      ? axiosError.response.data
      : generateDefaultError();
  }

  const err: Error = error;
  return generateDefaultError('', err.message);
};

const generateDefaultError = (
  code: string = '',
  message: string = '',
  errors: any[] = []
) => {
  return {code, message, errors};
};
