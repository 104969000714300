import React from 'react';
import {Button} from '@/components';
import {Button as SButton, Checkbox} from 'semantic-ui-react';

import {getCsv} from '../handlers';

type Props = {
  isLoading: boolean;
  sales: any;
  formProps: any;
  handleClick: () => void;
  setIsBid: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const Header: React.FC<Props> = ({
  isLoading,
  sales,
  formProps,
  handleClick,
  setIsBid,
  setModal,
}) => (
  <div className="b__create">
    <span className="totalCount">
      件数&nbsp;
      <span className="count totalCount">
        {sales.pagination.totalCount.toLocaleString()}
      </span>
      &nbsp;件
    </span>
    <div className="csv_output">
      <div className="csv_output--bid">
        <Checkbox
          label="落札済みのみを表示"
          disabled={isLoading}
          onChange={(_, {checked}) => {
            formProps.setFieldValue('selections', []);
            formProps.setFieldValue('ratingTargets', []);

            setIsBid(checked);
          }}
        />
      </div>

      <div className="csv_output--clear">
        <Button.Round
          disabled={!formProps.values.selections.length || isLoading}
          color="main"
          onClick={() => {
            formProps.setFieldValue('selections', []);
            formProps.setFieldValue('ratingTargets', []);
          }}
        >
          選択済みを解除
        </Button.Round>
      </div>

      <Button.Round
        onClick={() => {
          getCsv(formProps.values.selections, formProps);
        }}
        color="main"
        disabled={!formProps.values.selections.length}
        className="csv_output_button"
      >
        送付先をCSV出力する
      </Button.Round>
      <Button.Round
        onClick={() => {
          setModal(true);
        }}
        color="main"
        disabled={!formProps.values.ratingTargets.length}
        className="csv_output_button"
      >
        まとめてレビューする
      </Button.Round>
      <SButton className="csv_explanation_button" onClick={handleClick}>
        CSV出力について
      </SButton>
    </div>
  </div>
);

export default Header;
